import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Card, CardContent, Typography, Slider, Button, AppBar, IconButton, Divider } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MapPreview from '../MapPreview';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import debounce from 'lodash.debounce';
import { CustomThemeProvider } from '../../theme';
import axios from 'axios';
import TopBar from '../TopBar';
import '../../App.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import io from 'socket.io-client';

const SetPrice = () => {
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("America/Chicago");
  const { bookingId, pickupCoordinates, dropoffCoordinates } = useParams();
 
  const [distance, setDistance] = useState(0); // Distance in miles
  const [duration, setDuration] = useState(0); // Duration in minutes
  const [previewPrice, setPreviewPrice] = useState(0);

  useEffect(() => {
    // Connect to the socket server
    const newSocket = io('https://bransonrideshare.com', {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
    });
    setSocket(newSocket);

    // Clean up on component unmount
    return () => newSocket.close();
  }, [setSocket]);


  const calculateFairPrice = (distance) => {
    const basePrice = distance <= 15 ? distance * (1 / 0.8) : distance * (1.5 / 2);
    return Math.max(7, Math.round(basePrice));
  };
  
  const calculateMaxPrice = (distance) => {
    const upperBound = 10; // Upper bound for the shortest distance
    const lowerBound = 6; // Lower bound for the longest distance
    const scalingFactor = Math.log(distance + 1) / Math.log(2);
    let maxPriceMultiplier = upperBound - (scalingFactor * (upperBound - lowerBound) / (Math.log(25 + 1) / Math.log(2)));
    maxPriceMultiplier = Math.max(lowerBound, maxPriceMultiplier);
    return Math.max(28, Math.round(calculateFairPrice(distance) * maxPriceMultiplier));
  };
  const [price, setPrice] = useState(() => calculateFairPrice(distance) / 2);

  useEffect(() => {
    const osrmRouteUrl = `https://router.project-osrm.org/route/v1/driving/${pickupCoordinates};${dropoffCoordinates}?overview=false`;
    fetch(osrmRouteUrl)
      .then(response => response.json())
      .then(data => {
        const route = data.routes[0];
        setDistance(route.distance / 1609.34); // Convert meters to miles
        setDuration(route.duration / 60); // Convert seconds to minutes
        const initialPrice = calculateFairPrice(distance) / 2;
        setPrice(initialPrice);
      })
      .catch(error => console.error('Error fetching route:', error));
  }, [pickupCoordinates, dropoffCoordinates]);
  
 
  const handlePreviewChange = (event, newValue) => {
    setPreviewPrice(newValue);
  };

  const handlePriceChange = debounce((event, newValue) => {
    setPrice(newValue);
    if (Math.abs(newValue - price) >= 5) {
      Haptics.impact({ style: ImpactStyle.Light });
    }
  }, 100);


  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleUpdateBookingPrice = (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Start the loading state
    socket.emit('updateBookingPrice', { bookingId, newPrice: price });
  };
  useEffect(() => {
    if (socket) {
      socket.on('bookingPriceUpdated', (data) => {
        setTimeout(() => {
          navigate('/');
          setIsSubmitting(false); // Reset the isSubmitting state after navigation
        }, 1500);
      });

      socket.on('errorUpdatingBookingPrice', (errorMessage) => {
        console.error(errorMessage);
      });
    }
  }, [socket, navigate]);

  

  const handleBack = () => {
    navigate(`/`);
  };
  return (
    
    <CustomThemeProvider>
    <AppBar position="fixed" style={{ backgroundColor: '#001623', zIndex: 1200 }}>
      <TopBar />
    </AppBar>
    <Container maxWidth="sm" sx={{ py: 4, mt: 13 }}>
      <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '30px', backgroundColor: '#e2e3e0' }}>
      <CardContent>
            <Typography component="h6" variant="h6" sx={{margin:'10px'}}>
            <Typography component="h1" variant="h5" align="left">
    <IconButton size="small" onClick={handleBack} sx={{ position: 'absolute', width: '14%', }}>
      <ArrowBackIcon />
    </IconButton>
    </Typography>
   
    <strong>Pick your price</strong>
 
            </Typography>
            <MapPreview
              pickupCoordinates={pickupCoordinates.split(',').map(Number)}
              dropoffCoordinates={dropoffCoordinates.split(',').map(Number)}
              size="160px"
            />
            <Typography variant="h6" gutterBottom sx={{marginTop:'14px', fontSize:'16px'}}>
              Distance: <strong>{distance.toFixed(1)} mi</strong> - Est. Time: <strong>{duration.toFixed(0)} mins</strong>
            </Typography>
           
           
            <Slider
              value={previewPrice}
              onChange={handlePreviewChange}
              onChangeCommitted={handlePriceChange}
              aria-labelledby="input-slider"
              min={calculateFairPrice(distance)}
              max={calculateMaxPrice(distance)}
              step={1}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => `$${value.toFixed(2)}`}
              sx={{
                width: "90%",
                marginTop:'-10px',
                '& .MuiSlider-rail': {
                  opacity: 1,
                  background: 'linear-gradient(to right, #FFCF4E 3%, #85e085 35%)',
                },
                '& .MuiSlider-track': {
                  background: 'none',
                },
                '& .MuiSlider-thumb': {
                  width: 45,
                  height: 45,
                  backgroundColor: '#C9CCCA',
                  transition: 'transform 0.2s ease-in-out',
                  border: '2px solid currentColor',
                  '&:hover, &:focus, &:active': {
                    boxShadow: '0 0 0 8px rgba(255, 179, 71, 0.16)',
                  },
                },
                '& .MuiSlider-valueLabel': {
                  background: ' #F8FCFA',
                  color: '#000',
                  fontSize: '1.7rem',
                  fontWeight:'bold',
                  top: -20,
                  '&::before': {
                    display: 'none',
                  },
                },
              }}
            />
            <Button variant="contained" color="primary" onClick={handleUpdateBookingPrice} sx={{ fontSize: 17, px: 2, py: 1, width: '70%', marginTop: "24px", color: "#dee6e1" }}>
              <ArrowForwardIcon sx={{ fontSize: '30px' }} />
            </Button>
          </CardContent>
      </Card>
    </Container>
  </CustomThemeProvider>

  );
};

export default SetPrice;