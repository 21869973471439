import React, { useState } from 'react';
import { Modal, Box, Typography, Rating, TextField, Button } from '@mui/material';

function RateAndArchiveModal({ modalOpen, onClose, onSubmit }) {
  const [rating, setRating] = useState(0);
  const [details, setDetails] = useState('');

  const handleSubmit = () => {
    // Call the onSubmit prop with the rating and additional thoughts
    onSubmit(rating, details);
    // Close the modal
    onClose();
  };

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <Box sx={{ position: 'absolute', width:'90%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 1 }}>
        <Typography variant="h6" component="h2">
          Rate your driver
        </Typography>
        <Rating
          name="driver-rating"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
        <TextField
          label="Additional thoughts (optional)"
          multiline
          rows={4}
          value={details}
          onChange={(event) => setDetails(event.target.value)}
          fullWidth
          margin="normal"
        />
        <Button onClick={handleSubmit} variant="contained" sx={{ mt: 2 }}>
          Submit
        </Button>
      </Box>
    </Modal>
  );
}

export default RateAndArchiveModal;