import React, { useEffect, useRef, useState } from 'react';
import maplibre from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import pickupIconUrl from '../user-hand-up.svg';
import dropoffIconUrl from '../car.svg';

const PickupArrivalMap = ({ bookingId, pickupCoordinates, driverLocation }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const driverMarkerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (!map.current) {
      map.current = new maplibre.Map({
        container: mapContainer.current,
        style: `https://api.maptiler.com/maps/375454a6-17cd-40cb-b2db-6e53a17bd57c/style.json?key=pztu15Xn04G2MlPtYm9W`,
        center: pickupCoordinates,
        zoom: 17
      });

      map.current.on('load', () => {
        console.log('Map loaded');

        // Add the pickup location marker
        new maplibre.Marker({ color: "#19B880" })
          .setLngLat(pickupCoordinates)
          .addTo(map.current);
      });
    }

    if (map.current && driverLocation) {
      if (!driverMarkerRef.current) {
        // Initialize the driver location marker when driverLocation is first available
        driverMarkerRef.current = new maplibre.Marker({
          element: createCustomMarkerElement(dropoffIconUrl),
          anchor: 'bottom'
        })
        .setLngLat(driverLocation)
        .addTo(map.current);

        // Center the map around driverLocation
        map.current.setCenter(driverLocation);
      } else {
        // Update the driver marker position when driverLocation changes
        driverMarkerRef.current.setLngLat(driverLocation);
      }

      // Adjust the map view to include the updated driver location
      if (!isFullScreen) {
        const bounds = new maplibre.LngLatBounds(driverLocation, pickupCoordinates);
        map.current.fitBounds(bounds, {
          padding: 33,
          duration: 300
        });
      }
    }
  }, [pickupCoordinates, driverLocation, isFullScreen]);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (map.current) {
      map.current.resize(); // Ensure the map adjusts to the new size
      if (!isFullScreen) {
        // Enable map interaction
        map.current.boxZoom.enable();
        map.current.scrollZoom.enable();
        map.current.dragPan.enable();
        map.current.dragRotate.enable();
        map.current.keyboard.enable();
        map.current.doubleClickZoom.enable();
        map.current.touchZoomRotate.enable();
      } else {
        // Disable map interaction
        map.current.boxZoom.disable();
        map.current.scrollZoom.disable();
        map.current.dragPan.disable();
        map.current.dragRotate.disable();
        map.current.keyboard.disable();
        map.current.doubleClickZoom.disable();
        map.current.touchZoomRotate.disable();
      }
    }
  };

  // Disable map interaction initially
  useEffect(() => {
    if (map.current) {
      map.current.boxZoom.disable();
      map.current.scrollZoom.disable();
      map.current.dragPan.disable();
      map.current.dragRotate.disable();
      map.current.keyboard.disable();
      map.current.doubleClickZoom.disable();
      map.current.touchZoomRotate.disable();
    }
  }, []);

  function createCustomMarkerElement(iconUrl) {
    const el = document.createElement('div');
    el.className = 'custom-marker';
    el.style.backgroundImage = `url(${iconUrl})`;
    el.style.width = '55px';
    el.style.height = '55px';
    el.style.backgroundSize = 'cover';
    el.style.maxWidth = '100%';
    el.style.maxHeight = '100%';
    el.style.overflow = 'hidden';
    el.style.filter = 'drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.5))'; // Subtle drop shadow
    el.style.zIndex = '10'; // Ensure the car icon is on top
    return el;
  }

  return (
    <div ref={mapContainer} className={`map-container ${isFullScreen ? 'full-screen' : ''}`} style={{ width: '100%', height: isFullScreen ? '100vh' : '400px' }}>
      <IconButton onClick={toggleFullScreen} style={{ 
        position: 'absolute', 
        top: isFullScreen ? 60 : 10,
        right: 10, 
        zIndex: isFullScreen ? 1100 : 1, // Ensure the button is above the top bar when expanded
        fontSize: '32px' // Adjust the font size as needed
      }}>
        {isFullScreen ? <FullscreenExitIcon fontSize="inherit" /> : <FullscreenIcon fontSize="inherit" />}
      </IconButton>
    </div>
  );
};

export default PickupArrivalMap;
