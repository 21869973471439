
  import React, { useState, useEffect, useCallback, useRef, createContext } from 'react';
  import { useNavigate, Link } from 'react-router-dom';
  import axios from 'axios';


 
  import { Container, Card, CardContent, Typography, Box, IconButton, Divider, Table, TextField, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, AppBar,   Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TopBar from '../components/TopBar'; // Assuming you have a TopBarBooking component
import '../App.css';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { CustomThemeProvider } from '../theme';
axios.defaults.withCredentials = true;
const Account = () => {
 
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [authChecked, setAuthChecked] = useState(false);
    const [username, setUsername] = useState(null);
  const [userPhotoURL, setUserPhotoURL] = useState(null);
  const [photoURL, setPhotoURL] = useState('');


  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPassword('');
    setError('');
  };

  const handleDelete = async () => {
    try {
      const result = await FirebaseAuthentication.getIdToken();
        const token = result.token;
      await FirebaseAuthentication.signInWithEmailAndPassword({ email, password });
      
      // Delete the user
      await FirebaseAuthentication.deleteUser();

      if (!user || !user.uid) {
        alert('User ID is not available. Please try again.');
        return;
      }
  
      try {
        
        if (token) {
        const response = await axios.post(`https://bransonrideshare.com/delete-user`, {
          uid: user.uid
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }
      } catch (error) {
        console.error('Photo URL change failed:', error);
        alert('Server error: ' + error);
      }
      handleClose();
          navigate('/');
  
    } catch (error) {
      setError('Failed to delete account. Please check your password and try again.');
    }
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const result = await FirebaseAuthentication.getIdToken();
        const token = result.token;

        if (token) {
          const response = await axios.get(`https://bransonrideshare.com/user-verify-token`, {
            headers: { Authorization: `Bearer ${token}` }
          });

          if (response.data.valid) {
            setUser({ uid: response.data.userId });
            setUsername(response.data.username || '');
            setUserPhotoURL(response.data.photoURL);
            setAuthChecked(true);

          
          } else {
            localStorage.removeItem('userToken');
            setUser(null);
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setUser(null);
      }
      setLoading(false);
    };

    checkAuthStatus();
  }, [authChecked]);

  const handlePhotoURL = async (e) => {
    e.preventDefault();
    if (!user || !user.uid) {
      alert('User ID is not available. Please try again.');
      return;
    }

    try {
      const response = await axios.post(`https://bransonrideshare.com/user-photoURL`, {
        uid: user.uid,
        photoURL
      });

      if (response.status === 201) {
        navigate('/');
      }
    } catch (error) {
      console.error('Photo URL change failed:', error);
      alert('Server error: ' + error);
    }
  };

 
  const handleDefaultPhotoClick = async (url) => {
    if (!user || !user.uid) {
      alert('User ID is not available. Please try again.');
      return;
    }

    try {
      const response = await axios.post(`https://bransonrideshare.com/user-photoURL`, {
        uid: user.uid,
        photoURL: url
      });

      if (response.status === 201) {
        navigate('/');
      }
    } catch (error) {
      console.error('Photo URL change failed:', error);
      alert('Server error: ' + error);
    }
  };

  const defaultPhotos = [
    '../defaultprofile1.png',
    '../defaultprofile2.png',
    '../defaultprofile3.png',
    '../defaultprofile4.png',
    '../defaultprofile5.png',
    '../defaultprofile6.png',
  ];


  const handleBookNow = () => {
    localStorage.setItem('user', JSON.stringify({
      uid: user.uid,
      displayName: user.username,
      photoURL: user.photoURL,

    }));



    navigate('/selectdate/'+user.uid);
  };
  const handleBack = () => {
    navigate(`/`);
  };

  return (
    <CustomThemeProvider>
    <AppBar position="fixed" style={{ backgroundColor: '#001623', zIndex: 1200 }}>
    <TopBar user={user} handleBookNow={handleBookNow}  photoURL={userPhotoURL} />
    </AppBar>
    <Container component="main" maxWidth="sm">
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To delete your account, please sign in to confirm.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!error}
            helperText={error}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Card sx={{ mt: 24, p: 2 }}>
        <CardContent>
        <Typography component="h6" variant="h6" sx={{margin:'10px'}}>
            <Typography component="h1" variant="h5" align="left">
    <IconButton size="small" onClick={handleBack} sx={{ position: 'absolute', width: '14%', }}>
      <ArrowBackIcon />
    </IconButton>
    </Typography>
   
    <strong>Hi, {username}</strong>
 
            </Typography>
       
          <br></br>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow></TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography sx={{textAlign:'center', fontSize:'14px'}}><strong>Choose a default profile photo:</strong></Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                      {defaultPhotos.map((url, index) => (
                        <img
                          key={index}
                          src={url}
                          alt={`Default profile ${index + 1}`}
                          style={{ width: 45, height: 45, borderRadius: '50%', cursor: 'pointer' }}
                          onClick={() => handleDefaultPhotoClick(url)}
                        />
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Box sx={{ textAlign: 'center',}}><Typography><strong>Or photo URL: </strong></Typography><Typography sx={{fontSize:'14px', alignItems:'right'}}>(helps driver find you)</Typography></Box>
                    
                    <Box><Typography></Typography></Box>
                    <form onSubmit={handlePhotoURL}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="photoURL"
                        name="photoURL"
                        autoComplete="photoURL"
                        placeholder="[any web hosted image URL]"
                        value={photoURL}
                        onChange={(e) => setPhotoURL(e.target.value)}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{  mb: 2, backgroundColor: "#202E40" }}
                      >
                        Submit
                      </Button>
                    </form>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer><br></br><br></br><br></br><br></br>
          <Divider sx={{marginBottom:'5px', fontSize:'5px', backgroundColor:'#825353'}}></Divider>
         
      <Button variant="contained" color="secondary" onClick={handleClickOpen} sx={{backgroundColor:'#7A5B5B', color:'#E0DCDA'}}>
        Delete Account
      </Button>
      <br>
      </br>
      (Permenant, and you'll lose your ride requests/history)
 <br>
 </br>

        </CardContent>
      </Card>
    </Container>
    </CustomThemeProvider>
  );
};

export default Account;