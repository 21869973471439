import React, { useEffect, useRef, useState } from 'react';
import maplibre from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { IconButton, Box, Typography } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import pickupIconUrl from '../user-hand-up.svg';
import dropoffIconUrl from '../car.svg';

const DriverEnRouteMap = ({ bookingId, pickupCoordinates, driverLocation }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const driverMarkerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (!map.current) {
      map.current = new maplibre.Map({
        container: mapContainer.current,
        style: `https://api.maptiler.com/maps/5e93952d-a638-4302-8ab2-bab246582ff4/style.json?key=UHHUx3iqEIPjCVMQT4MD`,
        center: pickupCoordinates,
        zoom: 15
      });

      map.current.on('load', () => {
        console.log('Map loaded');
        new maplibre.Marker({
          element: createCustomMarkerElementSm(pickupIconUrl),
          anchor: 'bottom'
        })
        .setLngLat(pickupCoordinates)
        .addTo(map.current);



        //here it won't show since driverLocation doesn't exist yet, so i could spawn it here invisible with pickupCoordinates as the LngLat at first?

      });
    }

    if (map.current && driverLocation) {
      if (!driverMarkerRef.current) {
        // Initialize the driver location marker when driverLocation is first available
        driverMarkerRef.current = new maplibre.Marker({
          element: createCustomMarkerElement(dropoffIconUrl),
          anchor: 'bottom'
        })
        .setLngLat(driverLocation)
        .addTo(map.current);
      } else {
        // Update the driver marker position when driverLocation changes
        driverMarkerRef.current.setLngLat(driverLocation);
      }

      // Add a dot marker for the new driver location
      new maplibre.Marker({
        element: createDotMarkerElement(),
        anchor: 'center'
      })
      .setLngLat(driverLocation)
      .addTo(map.current);

      // Fetch and display the route
      fetchRoute();
    }
  }, [pickupCoordinates, driverLocation]);


  const fetchRoute = () => {
    const osrmRouteUrl = `https://router.project-osrm.org/route/v1/driving/${driverLocation[0]},${driverLocation[1]};${pickupCoordinates[0]},${pickupCoordinates[1]}?overview=full&geometries=geojson`;

    fetch(osrmRouteUrl)
      .then(response => response.json())
      .then(data => {
        const route = data.routes[0].geometry;

        if (map.current.getSource('route')) {
          map.current.getSource('route').setData(route);
        } else {
          map.current.addSource('route', {
            type: 'geojson',
            data: route
          });

          map.current.addLayer({
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': '#5D698A',
              'line-width': 5
            }
          });
        }
        if (!isFullScreen) {
          const bounds = new maplibre.LngLatBounds(driverLocation, pickupCoordinates);
          map.current.fitBounds(bounds, {
            padding: 40,
            duration: 300
          });
        }




        
      });
  };


  useEffect(() => {
    // Call the OSRM routing service to get the distance and duration
    const osrmRouteUrl = `https://router.project-osrm.org/route/v1/driving/${driverLocation};${pickupCoordinates}?overview=false`;

    fetch(osrmRouteUrl)
      .then(response => response.json())
      .then(data => {
        const route = data.routes[0];
        setDistance(route.distance / 1609.34); // Convert meters to miles
        setDuration(route.duration / 60); // Convert seconds to minutes
   
      })
      .catch(error => console.error('Error fetching route:', error));
  }, [driverLocation, pickupCoordinates]);



  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (map.current) {
      map.current.resize(); // Ensure the map adjusts to the new size
      if (!isFullScreen) {
        // Enable map interaction
        map.current.boxZoom.enable();
        map.current.scrollZoom.enable();
        map.current.dragPan.enable();
        map.current.dragRotate.enable();
        map.current.keyboard.enable();
        map.current.doubleClickZoom.enable();
        map.current.touchZoomRotate.enable();
      } else {
        // Disable map interaction
        map.current.boxZoom.disable();
        map.current.scrollZoom.disable();
        map.current.dragPan.disable();
        map.current.dragRotate.disable();
        map.current.keyboard.disable();
        map.current.doubleClickZoom.disable();
        map.current.touchZoomRotate.disable();
      }
    }
  };

  // Disable map interaction initially
  useEffect(() => {
    if (map.current) {
      map.current.boxZoom.disable();
      map.current.scrollZoom.disable();
      map.current.dragPan.disable();
      map.current.dragRotate.disable();
      map.current.keyboard.disable();
      map.current.doubleClickZoom.disable();
      map.current.touchZoomRotate.disable();
    }
  }, []);

  function createCustomMarkerElementSm(iconUrl) {
    const el = document.createElement('div');
    el.className = 'custom-marker';
    el.style.backgroundImage = `url(${iconUrl})`;
    el.style.width = '35px';
    el.style.height = '35px';
    el.style.backgroundSize = 'cover';
    el.style.maxWidth = '100%';
    el.style.maxHeight = '100%';
    el.style.overflow = 'hidden';
    return el;
  }
  function createCustomMarkerElement(iconUrl) {
    const el = document.createElement('div');
    el.className = 'custom-marker';
    el.style.backgroundImage = `url(${iconUrl})`;
    el.style.width = '55px';
    el.style.height = '55px';
    el.style.marginTop = '17px';
    el.style.backgroundSize = 'cover';
    el.style.maxWidth = '100%';
    el.style.maxHeight = '100%';
    el.style.overflow = 'hidden';
    el.style.filter = 'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5))'; // Subtle drop shadow
    el.style.zIndex = '10'; // Ensure the car icon is on top
    return el;
  }
  
  function createDotMarkerElement() {
    const el = document.createElement('div');
    el.className = 'dot-marker';
    el.style.width = '9px';
    el.style.height = '9px';
    el.style.backgroundColor = '#2E302E';
    el.style.borderRadius = '50%';
    el.style.zIndex = '5'; // Ensure the dots are below the car icon
    return el;
  }
  return (
    <Box style={{ width: '100%' }}>
    <div ref={mapContainer} className={`map-container ${isFullScreen ? 'full-screen' : ''}`} style={{ width: '100%', height: isFullScreen ? '100vh' : '400px' }}>
      <IconButton onClick={toggleFullScreen} style={{ 
        position: 'absolute', 
        top: isFullScreen ? 60 : 10,
        right: 10, 
        zIndex: isFullScreen ? 1100 : 1, // Ensure the button is above the top bar when expanded
        fontSize: '32px' // Adjust the font size as needed
      }}>
        {isFullScreen ? <FullscreenExitIcon fontSize="inherit" /> : <FullscreenIcon fontSize="inherit" />}
      </IconButton>
    </div>

    {distance && (
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{marginTop:'-1px', zIndex:1250}}>
 <Typography variant="subtitle1">
 <strong>{distance.toFixed(1)} mi</strong> left
</Typography>
<Typography variant="subtitle1">
 <strong>{Math.round(duration)}</strong> mins
</Typography>

</Box>
      )}
    </Box>
  );
};

export default DriverEnRouteMap;