import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import axios from 'axios'; // Now we're using axios
import { Container, Card, CardContent, Typography, TextField, Button, AppBar, Box, Tooltip,  IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FlagIcon from '@mui/icons-material/Flag';
import InfoIcon from '@mui/icons-material/Info';

import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import PinDropIcon from '@mui/icons-material/PinDrop';
import EastIcon from '@mui/icons-material/East';
import { MoreVert } from '@mui/icons-material';
import { ArrowDownward } from '@mui/icons-material';
import MapPreview from '../MapPreview'; // Assuming you have a MapPreview component
import { CustomThemeProvider } from '../../theme';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import TopBar from '../TopBar';
import '../../App.css';
//import { useAuthState } from 'react-firebase-hooks/auth';
import HailIcon from '@mui/icons-material/Hail';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

axios.defaults.withCredentials = true;
const Confirmation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);
  const [user, setUser] = useState(null);
  const [userPhotoURL, setUserPhotoURL] = useState(null);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("America/Chicago");
  const socket = io('https://bransonrideshare.com', {
    transports: ['websocket', 'polling'],
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
  });
 const [distance, setDistance] = useState(0); // Distance in miles
 //const socket = io(process.env.REACT_APP_API_URL);

 useEffect(() => {
  const checkAuthStatus = async () => {
    try {
      const result = await FirebaseAuthentication.getIdToken();
      const token = result.token;

      if (token) {
        const response = await axios.get(`https://bransonrideshare.com/user-verify-token`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.valid) {
          setUser({ uid: response.data.userId });

          setUserPhotoURL(response.data.photoURL);
          setAuthChecked(true);

        } else {
          localStorage.removeItem('userToken');
          setUser(null);
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      setUser(null);
    }
    setLoading(false);
  };

  checkAuthStatus();
}, [authChecked]);

  const { username, datetime, pickupCoordinates, dropoffCoordinates, pickupAddress, dropoffAddress, price } = useParams();

  

  const [routeDetails, setRouteDetails] = useState({ distanceInMiles: 0, durationInMinutes: 0 });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const simplifyAddress = (fullAddress) => {
    const addressParts = fullAddress.split(','); // Split the address by comma
    const streetNumberAndName = addressParts[0]; // Assuming the street number and name are the first part
    return streetNumberAndName.trim(); // Trim any extra whitespace
  };
  // Function to format coordinates
  const formatCoordinates = (coords) => {
    // Ensure that coords is a string before attempting to split
    return typeof coords === 'string' ? coords.split(',').map(Number) : [0, 0];
  };
  useEffect(() => {
    if (dropoffCoordinates) {
      const osrmRouteUrl = `https://router.project-osrm.org/route/v1/driving/${pickupCoordinates};${dropoffCoordinates}?overview=false`;
  
    fetch(osrmRouteUrl)
      .then(response => response.json())
      .then(data => {
        const route = data.routes[0];
        setDistance(route.distance / 1609.34); // Convert meters to miles
  
      })
      .catch(error => console.error('Error fetching route:', error));
    }
    // Call the OSRM routing service to get the distance and duration
    
  }, [pickupCoordinates, dropoffCoordinates]);
  // const reverseGeocode = async (coordinates) => {
  //   try {
  //     const response = await axios.get(`https://us1.locationiq.com/v1/reverse.php?key=pk.5f3d4419ad75b5aea1eafbafcde87a49&lat=${coordinates[1]}&lon=${coordinates[0]}&format=json`);
  //     // Extract the road and house number from the response
  //     const { road, house_number } = response.data.address;
  //     // Format the address as "House Number Road"
  //     const formattedAddress = house_number ? `${house_number} ${road}` : road;
  //     return formattedAddress; // Return the formatted address
  //   } catch (error) {
  //     console.error('Error fetching address:', error);
  //     return '';
  //   }
  // };







  const formatTimeRangeForConfirmation = (requestedDate) => {
    const HALF_HOUR = 1800000; // Half an hour in milliseconds
    const currentTime = new Date().getTime();
    const requestedDateTime = new Date(requestedDate).getTime();
  
    // Check if the requested time is less than 30 minutes from now
    if (requestedDateTime - currentTime < HALF_HOUR) {
      // Calculate the end time of the range by adding 40 minutes to the current time
      const endTime = new Date(currentTime + (40 * 60000));
      // Check if the requested time is for today
      const isToday = new Date().toDateString() === new Date(requestedDate).toDateString();
      const dateDisplay = isToday ? 'Today' : new Date(requestedDate).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' });
      
      return `${dateDisplay} | ${new Date(currentTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} - ${endTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
    } else {
      // If the requested time is more than 30 minutes in advance, just show the requested time
      const isToday = new Date().toDateString() === new Date(requestedDate).toDateString();
      const dateDisplay = isToday ? 'Today' : new Date(requestedDate).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' });
      
      return `${dateDisplay} | ${new Date(requestedDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
    }
  };


  const timeDisplayForConfirmation = formatTimeRangeForConfirmation(datetime);







  // Function to calculate route details
  const calculateRouteDetails = async (pickupCoords, dropoffCoords) => {
    // Ensure coordinates are in the correct format and are numbers
    const formattedPickupCoords = formatCoordinates(pickupCoords);
    const formattedDropoffCoords = formatCoordinates(dropoffCoords);

    // Construct the OSRM API request URL
    const osrmRequestUrl = `https://router.project-osrm.org/route/v1/driving/${formattedPickupCoords[0]},${formattedPickupCoords[1]};${formattedDropoffCoords[0]},${formattedDropoffCoords[1]}?overview=false`;

    try {
      const response = await axios.get(osrmRequestUrl);
      const route = response.data.routes[0];
      const distanceInMiles = route.distance / 1609.34; // Convert meters to miles
      const durationInMinutes = route.duration / 60; // Convert seconds to minutes
      return { distanceInMiles, durationInMinutes };
    } catch (error) {
      console.error('Error fetching route:', error);
      return { distanceInMiles: 0, durationInMinutes: 0 };
    }
  };

  useEffect(() => {

  localStorage.removeItem('routeDetails');

    const fetchDetails = async () => {
      const [routeInfo] = await Promise.all([

        calculateRouteDetails(pickupCoordinates, dropoffCoordinates)
      ]);

      setRouteDetails(routeInfo);
  
      // Save to local storage
      localStorage.setItem('routeDetails', JSON.stringify(routeInfo));
    };
  
    // Load from local storage
    const savedRouteDetails = JSON.parse(localStorage.getItem('routeDetails'));
    if (savedRouteDetails) {
      setRouteDetails(savedRouteDetails);
    } else {
      fetchDetails();
    }
  }, []);

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Start the loading state
    const formattedDatetime = dayjs(datetime).utc().toISOString();
    const additionalDetails = event.target.elements.additionalDetails.value;
    const parsedPickupCoordinates = formatCoordinates(pickupCoordinates);
    const parsedDropoffCoordinates = formatCoordinates(dropoffCoordinates);
    const preppedPickupAddress = simplifyAddress (pickupAddress);
    const preppedDropoffAddress = simplifyAddress (dropoffAddress);
    // Prepare the data object with all booking details
    const bookingData = {
      userId: username,
      displayName: user.username,
      date: formattedDatetime, // Make sure this is in ISO string format
      pickupLocation: {
        type: 'Point', // Include the type property
        coordinates: parsedPickupCoordinates
      },
      dropoffLocation: {
        type: 'Point', // Include the type property
        coordinates: parsedDropoffCoordinates
      },
      pickupAddress: preppedPickupAddress,
      dropoffAddress: preppedDropoffAddress,
      price: Number(price), // Ensure price is a number
      details: additionalDetails // Include any additional details provided
    };

    // Emit the 'createBooking' event with the booking data
    socket.emit('createBooking', bookingData);
    console.log(bookingData+"9283948329")
    // Navigate back after the booking is created
    setTimeout(() => {
      navigate('/');
      setIsSubmitting(false); // Reset the isSubmitting state after navigation
    }, 1500);
  };
  const handleBack = () => {
    const formattedDatetime = dayjs(datetime).utc().toISOString();
    // Logic to validate pickup address and fetch coordinates
    navigate(`/setprice/${username}/${formattedDatetime}/${pickupCoordinates}/${dropoffCoordinates}/${pickupAddress}/${dropoffAddress}`);
  };
  // Ensure price is a number before formatting
  const numericPrice = parseFloat(price);
  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while the user state is loading
  }
  
      return (
        <CustomThemeProvider>
          <AppBar position="fixed" style={{ backgroundColor: '#001623', zIndex: 1200 }}>
            <TopBar/>
          </AppBar>
          <Container maxWidth="sm" sx={{ height: '100vh',  py: 4, mt: 13 }}>
        <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#e2e3e0',  marginTop:'30px'  }}>
              <CardContent>
            {/* Price display box */}
            <Typography component="h6" variant="h6" sx={{margin:'10px'}}>
            <Typography component="h1" variant="h5" align="left">
    <IconButton size="small" onClick={handleBack} sx={{ position: 'absolute', width: '14%', }}>
      <ArrowBackIcon />
    </IconButton>
    </Typography>
   
    <strong>Confirmation</strong>
 
            </Typography>

    <Typography style={{color:"#333",fontSize:"14px" }}> <span> {simplifyAddress(pickupAddress)} </span>→  <span> {simplifyAddress(dropoffAddress)} </span></Typography>
    <Typography variant="subtitle1" color="text.alert" gutterBottom >
      <strong>
      {timeDisplayForConfirmation}
      </strong>
    </Typography>


                <MapPreview
                  pickupCoordinates={formatCoordinates(pickupCoordinates)}
                  dropoffCoordinates={formatCoordinates(dropoffCoordinates)}
                /> 
                
                
            
          

<Box 
                
                backgroundColor="#7CBAD9"
                borderRadius="5px"
                padding="1px 20px"
                boxShadow="0 0px 5px rgba(0, 16, 0, 0.2)"
                zIndex={0}
                position="relative"
                width="26%"

                marginTop="-44px"
              >
                  <Typography variant="body2" gutterBottom style={{fontSize: '21px',marginTop:'5px' }}>
             <strong>{distance.toFixed(1)} mi </strong>
            </Typography>

              </Box>



              <Box 

            
backgroundColor="#90EBB4"
borderRadius="5px"
padding="7px 20px"
boxShadow="0 0px 5px rgba(0, 16, 0, 0.2)"
zIndex={0}
position="relative"
width="26%"
marginLeft="64%"
marginTop="-43px"

>




  <Typography variant="body2" style={{ fontSize: '21px', fontWeight: 'bold', color: 'black' }}>
${numericPrice.toFixed(2)}
</Typography>
<Tooltip title="Pay in cash, Venmo or CashApp upon successful trip" placement="top" enterTouchDelay={0}>
  <InfoIcon style={{ position: 'absolute', top: '10%', right: '-3px', transform: 'translateY(-40%)', cursor: 'pointer' }} />
</Tooltip>
</Box>                   
          
<form style={{marginTop:'-9px', }} onSubmit={handleSubmit}>
            <TextField
  name="additionalDetails"
  label="Additional Details (optional)"
  multiline
  minRows={1} // Starts with one line
  maxRows={2} // Expands up to three lines
  fullWidth
  margin="normal"
  variant="outlined"
/>
                  <Button type="submit" variant="contained"  sx={{  padding:'0px',width:'100%',color:'#9ec0a1' ,fontSize:'56px', color:'#fbf6db'}} disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={30} style={{ color: 'white', padding:'15px' }} /> : <span >🚕<CheckCircleSharpIcon sx={{fontSize:'30px'}} /></span>}
                  </Button>

                </form>
                             
              




          </CardContent>
        </Card>
      </Container>
    </CustomThemeProvider>
  );
};

export default Confirmation;




            
