import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  Avatar,
  IconButton,
  ButtonBase,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  ButtonGroup

} from '@mui/material';


import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import SpeedSharpIcon from "@mui/icons-material/SpeedSharp";
import { styled, keyframes } from '@mui/system';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


dayjs.extend(utc);
dayjs.extend(timezone);

function TopBar({ user, handleLogout, photoURL}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(false);
  const [rideOption, setRideOption] = useState('Scheduled');

  const handleDashboardMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDashboardClose = () => {
    setAnchorEl(null);
  };

  const options = ["Scheduled", "ASAP"];

  const [imgSrc, setImgSrc] = React.useState(photoURL);


  
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
  
    const handleRequestButtonMenuItemClick = (event, index) => {
      setSelectedIndex(index);
  
      setOpen(false);
    };
  
    const handleRequestButtonToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
    const handleRedirect = () => {
      if (selectedIndex === 0) {
        navigate(`/selectdate/${user.uid}`);
      } else {
        // Logic to handle ASAP booking action
        const now = dayjs();
      const centralTime = now.tz('America/Chicago').format();
      navigate(`/selectlocations/${user.uid}/${centralTime}`);
      }
    };
  
    const handleRequestButtonClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };
    const shimmer = keyframes`
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  `;
  
    // Custom styled button with shimmer effect
    const ShimmerButton = styled(Button)({
      position: "relative",
      overflow: "hidden",
      fontWeight: "bold",
      fontSize: "1rem",
      color: "#FFFFFF",
  
      backgroundColor: "#234848",
      backgroundImage:
        "linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0.1) 75%, transparent 75%, transparent)",
      backgroundSize: "40rem 40rem",
      animation: `${shimmer} 45s linear infinite`,
      "&:hover": {
        backgroundColor: "#142929",
        boxShadow: "0 0 8px 0 rgba(255,255,255,0.3)",
      },
      "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: "linear-gradient(to right, #0f9b8e, #234848)",
        opacity: 0.2,
      },
      "& .MuiButton-label": {
        position: "absolute",
        zIndex: 1200,
        textTransform: "none", // Prevents text from being all uppercase
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Modern, sleek, flat font
        fontWeight: 1100, // Medium font weight
        fontSize: "1rem", // Adjust font size as needed
      },
    });
  
    const StyledDropdownButton = styled(Button)({
      backgroundColor: "#3B4040",
      backgroundImage:
        "linear-gradient(135deg, #333837 25%, #333837 25%, #333837 50%, #333837 50%, #333837 75%, #333837 75%, #333837)",
      backgroundSize: "1rem 1rem",
      "&:hover": {
        backgroundColor: "#2F3333",
        backgroundImage:
          "linear-gradient(135deg, #2F3333 25%, #2F3333 25%, #2F3333 50%, #2F3333 50%, #2F3333 75%, #2F3333 75%, #282c2f)",
      },
      // Icon color can be adjusted here if needed
      "& .MuiSvgIcon-root": {
        fill: "#c0c5c1",
      },
    });


  return (
 
    <AppBar position="static" style={{ backgroundColor: '#172229', paddingTop: '7px', zIndex:1205}}>
      <Toolbar>
        {/* Logo */}
        {user ? (
        <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'flex-start' }}>
           <ButtonBase className="button-base" onClick={() => navigate('/')} style={{ display: 'inline-block', lineHeight: 0 }}>
          <img
            src="/applogo.png"
            alt="Branson Rideshare Logo"
            style={{ height: '45px', width: 'auto', marginBottom:'5px', borderRadius:'9px' }}
          />
             </ButtonBase>
        </Box>
      ) : (
        <Box id="notch-adjust" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ cursor: 'pointer' }}>
  <ButtonBase className="button-base" onClick={() => navigate('/')} style={{ display: 'inline-block', lineHeight: 0 }}>
    <img src="/fullogo.png" alt="Branson Rideshare Logo" style={{ height: "150px", maxWidth: '380px', marginTop:'-87px',borderRadius: "0 0 25px 25px"

    }} />
  </ButtonBase>
</Box>
        </Box>
      )}

        {/* Request Ride Button */}
        {user && (
              <Box id="request-ride-button" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center',  paddingBottom:'7px' }}>
  <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Split button"
      >
        <ShimmerButton 
       
          onClick={handleRedirect}

        >
          Request Ride
        </ShimmerButton>
        <StyledDropdownButton
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select ride option"
          aria-haspopup="menu"
          onClick={handleRequestButtonToggle}
        >
          {selectedIndex === 0 ? (
            <CalendarMonthSharpIcon />
          ) : (
            <SpeedSharpIcon />
          )}
        </StyledDropdownButton>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleRequestButtonClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) =>
                        handleRequestButtonMenuItemClick(event, index)
                      }
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
</Box>
        )}

        {/* User profile and logout */}
        {user && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={user.displayName} src={photoURL} />
            <Box ml={1} sx={{ }}>
  {/* {user.displayName.split(' ')[0]} */}
</Box>
            <IconButton
              edge="end"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDashboardMenu}
              color="inherit"
            >
              <MenuSharpIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleDashboardClose}
            >
              <MenuItem onClick={() => navigate('/account')}>Account</MenuItem>
              <MenuItem onClick={() => navigate('/triphistory')}>
                Trip History
              </MenuItem>
              <MenuItem onClick={() => navigate('/about')}>About</MenuItem>
              <MenuItem onClick={() => navigate('/login')}>Logout</MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>

  );
}

export default TopBar;


