
import io from 'socket.io-client';
  import React, { useState, useEffect, useCallback, useRef, createContext } from 'react';
  import { useNavigate, Link } from 'react-router-dom';
  import axios from 'axios';
import { Container, Card, CardContent, TextField, Typography, Button, Box, Grid, Divider, IconButton, AppBar, Slide, Menu, MenuItem, styled} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowRightAltSharpIcon from '@mui/icons-material/ArrowRightAltSharp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { Tooltip } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
  import './App.css';
  import TopBar from './components/TopBar';
  import TopBarBooking from './components/TopBarBooking';
 import RateAndArchiveModal from './components/RateAndArchiveModal';
 import MapPreview from './components/MapPreview';
 import DriverEnRouteMap from './components/DriverEnRouteMap';
 import TowardDestinationMap from './components/TowardDestinationMap';
 import PickupArrivalMap from './components/PickupArrivalMap';
  import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
  import EventIcon from '@mui/icons-material/Event'; // Date icon
  import CallIcon from '@mui/icons-material/Call';
  import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from './creanimasi-kirim-send-svgrepo-com.svg';
  import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; // For Mileage
  import AccessTimeIcon from '@mui/icons-material/AccessTime'; // For Pick-up
  import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; 
  import { Modal } from '@mui/material';
  import { Rating } from '@mui/material';
  import { keyframes } from '@mui/system';
  import {
    MainContainer,
    ChatContainer,
    ConversationHeader,
    Avatar,
    MessageList,
    Message,
    MessageInput
  } from "@chatscope/chat-ui-kit-react";
  import { Preferences } from '@capacitor/preferences';
  import moment from 'moment';
  import { CustomThemeProvider } from './theme';
  import { Capacitor } from '@capacitor/core';
  import { getAuth } from 'firebase/auth';
  import {Messaging} from 'firebase/messaging';
  import { initializeApp } from 'firebase/app';
  import {getMessaging, getToken, onMessage } from 'firebase/messaging';
  import { getAnalytics } from "firebase/analytics";
  import { Keyboard } from '@capacitor/keyboard';
  import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
  import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
  } from '@capacitor/push-notifications';
  export const UserContext = createContext(null);
  axios.defaults.withCredentials = true;

  const firebaseConfig = {
    apiKey: "AIzaSyAC2SJx7RtF5yt7Ki7mmICUxedlZ9peI5s",
    authDomain: "branson-rideshare-49d0d.firebaseapp.com",
    projectId: "branson-rideshare-49d0d",
    storageBucket: "branson-rideshare-49d0d.appspot.com",
    messagingSenderId: "409023501671",
    appId: "1:409023501671:web:df771dbb31fbd47dc78bc2",
    measurementId: "G-LDEF9MHP6W"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const messaging = getMessaging(app);

  function App() {
    
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState(null);
    const [userPhotoURL, setUserPhotoURL] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [bookingLoading, setBookingsLoading] = useState(true);
    const [authChecked, setAuthChecked] = useState(false);

    const [bookings, setBookings] = useState([]);


   

   const socket = io('https://bransonrideshare.com', {
    transports: ['websocket', 'polling'],
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
  });
   const axiosInstance = axios.create({
     baseURL: 'https://bransonrideshare.com', 
     withCredentials: true 
   });

    const [driverUsername, setDriverUsername] = useState('');
    const [driverPhotoURL, setDriverPhotoURL] = useState('');

    const [showDriverInfo, setShowDriverInfo] = useState(false);
    const [activeRide, setActiveRide] = useState();
    const [driverLocation, setDriverLocation] = useState(null);



    

    const handleLogout = async () => {

      setUser(null);
      setAuthChecked(false); 
      navigate('/login'); 
    };

    const refreshToken = async () => {
      try {
        const result = await FirebaseAuthentication.getIdToken(true);
        const newToken = result.token;
        localStorage.setItem('userToken', newToken);
        return newToken;
      } catch (error) {
        console.error('Error refreshing token:', error);
        return null;
      }
    };
          // Handle foreground messages
          onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            // Customize notification here
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
              body: payload.notification.body,
              icon: '/applogo.png'
            };

            new Notification(notificationTitle, notificationOptions);
          });
    
//  Auth check    ----------------------------
  
useEffect(() => {
  const checkAuthStatus = async () => {
    try {
      let token = localStorage.getItem('userToken');
      if (!token) {
        token = await refreshToken();
      }

      if (token) {
        const response = await axios.get(`https://bransonrideshare.com/user-verify-token`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.valid) {
          setUser({ uid: response.data.userId });
          setUsername(response.data.username || '');
          setUserPhotoURL(response.data.photoURL);
          setAuthChecked(true);

          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');

              // Register service worker
              if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/firebase-messaging-sw.js')
                  .then(function(registration) {
                    console.log('Service Worker registered with scope:', registration.scope);
                  
                  }).catch(function(error) {
                    console.log('Service Worker registration failed:', error);
                  });
              }

              // Get FCM token
              getToken(messaging, { vapidKey: 'BPgP28dv4aXw_WoknV0eLo3XjBR-AC8r4jc2oiNeAzlOUz_rGBB7x81DYSzvp4-oWoJr3i-TG72dnU7n7oZtcDw' }).then((currentToken) => {
                if (currentToken) {
                  console.log('FCM Token:', currentToken);
                  // Send the token to your server
                  socket.emit('user-push-token', { deviceToken: currentToken, userId: response.data.userId });
                } else {
                  console.log('No registration token available. Request permission to generate one.');
                }
              }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
              });


              

            } else {
              console.log('Unable to get permission to notify.');
            }
          });
        } else {
          localStorage.removeItem('userToken');
          setUser(null);
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      setUser(null);
    }
    setLoading(false);
  };
  checkAuthStatus();
}, [authChecked]);
//-----------------------------------------------






    const [timeDisplay, setTimeDisplay] = useState({});
    const formatTimeRange = (bookingDate, requestedDate) => {
      const HALF_HOUR = 1800000; // Half an hour in milliseconds
      const currentTime = bookingDate ? new Date(bookingDate).getTime() : new Date().getTime();
      const requestedDateTime = new Date(requestedDate).getTime();
    
      // Check if the requested time is less than 30 minutes from the booking time or current time
      if (requestedDateTime - currentTime < HALF_HOUR) {
        // Calculate the end time of the range by adding 40 minutes to the booking time or current time
        const endTime = new Date(currentTime + (40 * 60000));
        // Check if the requested time is for today
        const isToday = new Date().toDateString() === new Date(requestedDate).toDateString();
        const dateDisplay = isToday ? 'Today' : new Date(requestedDate).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' });
        
        return `${dateDisplay} | <strong>${new Date(currentTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</strong> - <strong>${endTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</strong>`;
      } else {
        // If the requested time is more than 30 minutes in advance, just show the requested time
        const isToday = new Date().toDateString() === new Date(requestedDate).toDateString();
        const dateDisplay = isToday ? 'Today' : new Date(requestedDate).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' });
        
        return `${dateDisplay} | <strong>${new Date(requestedDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</strong>`;
      }
    };

  


    //Booking countdown
    const calculateTimeLeft = (bookingDate) => {
      const now = new Date();
      const twoHoursBefore = new Date(bookingDate.getTime() - 2 * 60 * 60 * 1000);
      let timeLeft = '';
    
      if (now >= twoHoursBefore && now <= bookingDate) {
        const diff = bookingDate - now;
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        timeLeft = `${hours}hr ${minutes}mins`;
      }
      return timeLeft;
    };


    useEffect(() => {
      if (user) {
        const requestBookings =  () => {
    console.log("bookings request"+user.uid)
          socket.emit('request-bookings', { userId: user.uid });
        };
    
        socket.on('bookings-data', (data) => {
          // First, sort the bookings by the ones closest to T+1h after the booking datetime
          const sortedBookings = data.sort((a, b) => {
            const now = new Date();
            const aDate = new Date(a.date);
            const bDate = new Date(b.date);
            const aDiff = Math.abs(now - aDate) - 3600000; // T+1h in milliseconds
            const bDiff = Math.abs(now - bDate) - 3600000;
            return aDiff - bDiff; 
          });
        
          const updatedBookings = sortedBookings.map(booking => {
            console.log("booking"+booking.date)
            const now = new Date();
            const bookingDate = new Date(booking.date);
            const twoHoursBefore = new Date(bookingDate.getTime() - 2 * 60 * 60 * 1000);
        
            // Calculate time left if within the 2-hour window
            let timeLeft = '';
            if (now >= twoHoursBefore && now <= bookingDate) {
              const diff = bookingDate - now;
              const hours = Math.floor(diff / (1000 * 60 * 60));
              const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
              timeLeft = `${hours}hr ${minutes}mins`;
            }
            return {
              ...booking,
              driverUsername: booking.driverUsername || 'N/A', 
              driverInfoPhotoURL: booking.driverInfoPhotoURL || 'N/A', 
              driverPhotoURL: booking.driverPhotoURL || 'N/A', 
              driverPhone: booking.driverPhone || null, 

              venmo: booking.venmo,
              cashapp: booking.cashApp,
              
              timeLeft // Adding in the timeLeft property to each booking
            };
          });
          setBookings(updatedBookings);

        const activeBooking = updatedBookings.find(booking => booking.status === 'enrouteToPickup');
              if (activeBooking) {
                setActiveRide(activeBooking._id);
              }
          // Update the unreadCounts state with the new data
          const newUnreadCounts = sortedBookings.reduce((counts, booking) => ({
            ...counts,
            [booking._id]: booking.userUnreadCount 
          }), {});
          setUnreadCounts(newUnreadCounts);
  
          const timeOrTimeRangePerBooking = sortedBookings.reduce((displays, booking) => ({
            ...displays,
            [booking._id]: formatTimeRange(booking.bookingDate, booking.date)
          }), {});
          setTimeDisplay(timeOrTimeRangePerBooking);

        });
    
        socket.on('bookings-error', (message) => {
          console.error('Error fetching bookings:', message);
        });
    
        requestBookings();
      const intervalId = setInterval(() => {
      setBookings(prevBookings => prevBookings.map(booking => {
      const bookingDate = new Date(booking.date);
      const timeLeft = calculateTimeLeft(bookingDate);

      return {
        ...booking,
        timeLeft // Update the timeLeft property
      };
    }));

        }, 45000); // Update every 45s
    
        return () => {
          socket.off('bookings-data');
          socket.off('bookings-error');
          socket.off('connect', requestBookings);
          socket.disconnect();
          clearInterval(intervalId); 
          setBookingsLoading(false );
        };
        
      }
   
    }, [user, authChecked]);


    useEffect(() => {
      // Function to handle the accepted booking update
      const handleAcceptedBookingUpdate = (update) => {
        setBookings(prevBookings => prevBookings.map(booking => {
          if (booking._id === update.bookingId) {
            return { ...booking, status: update.status, driverKey: update.driverKey, driverUsername: update.driverUsername, driverVehicleTypeAndPlate: update.driverVehicleTypeAndPlate, driverPhotoURL: update.driverPhotoURL, driverInfoPhotoURL: update.driverInfoPhotoURL, venmo: update.venmo, cashapp: update.cashApp};
          }
          return booking;
        }));
      };
  socket.on('accepted-booking-update', handleAcceptedBookingUpdate);
  return () => {
    socket.off('accepted-booking-update', handleAcceptedBookingUpdate);
  };
}, [socket]); 


const [messages, setMessages] = useState({});
const [unreadCounts, setUnreadCounts] = useState({});
const [currentChatBookingId, setCurrentChatBookingId] = useState(null);
const [showChat, setShowChat] = useState(false);
const [lastMessageTimeFormatted, setLastMessageTimeFormatted] = useState('');
useEffect(() => {
  // Function to handle new incoming messages
  const handleNewMessage = (messageData, chatState) => {
 
       setMessages(prevMessages => ({
        ...prevMessages,
        [messageData.bookingId]: [
          ...(prevMessages[messageData.bookingId] || []),
          messageData
        ]
      }));
      if (chatState.userUnreadCount) {
        setUnreadCounts(counts => ({
          ...counts,
          [chatState.bookingId]: chatState.userUnreadCount
        }));
      }
  };

  socket.on('new-message', handleNewMessage);
  return () => {
    socket.off('new-message', handleNewMessage);
  };
}, [socket]);

      
  const handleToggleChat = (bookingId) => {
        socket.emit('chat-active', { bookingId: bookingId, userType: 'user' });
  };

useEffect(() => {

  const handleExistingMessages = (data) => {
      setCurrentChatBookingId(data.bookingId);
      setDriverUsername(data.driverUsername);
      setDriverPhotoURL(data.driverPhotoURL);

      if (data.messages) {
        setMessages({
          ...messages,
          [data.bookingId]: data.messages
        });
        const lastMessage = data.messages[data.messages.length - 1];
        if (lastMessage) {
          setLastMessageTimeFormatted("Last message: "+moment(lastMessage.timestamp).format('M/D [at] h:mm a'));
        } else {
          setLastMessageTimeFormatted('');
        }
        
      }
      
      if (data.activeUserType === 'user') {
        setShowChat(true);
        setUnreadCounts(counts => ({
          ...counts,
          [data.bookingId]: 0
        }));
      } 
      

     
  };
  socket.on('existing-messages', handleExistingMessages);
  return () => {
    socket.off('existing-messages', handleExistingMessages);
  };
}, [socket, currentChatBookingId, messages]);




    socket.on('booking-archived', (archivedBookingId) => {
      console.log('Booking archived successfully');
      // Use a functional update to ensure we're working with the most recent state
      setBookings(prevBookings => {
        // Create a new array without the archived booking
        return prevBookings.filter(b => b._id !== archivedBookingId);
      });
      socket.off('booking-archived');
    });

    const handleBookNow = () => {
      localStorage.setItem('user', JSON.stringify({
        uid: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,

      }));

      navigate('/selectdate/'+user.uid);
    };
  

const [confirmedStatus, setConfirmedStatus] = useState({});
const [confirmingCancelId, setConfirmingCancelId] = useState(null);
const [cancelTimer, setCancelTimer] = useState(null);




const handleCancelBooking = async (bookingId) => {
  if (confirmingCancelId === bookingId) {
    // Proceed with the cancellation logic
    try {  
      socket.emit('cancel-booking', { bookingId });
      // Update the bookings state to reflect the change
      setBookings(bookings.filter(booking => booking._id !== bookingId));
      // Reset the confirmed status for this booking
      setConfirmedStatus(prev => ({ ...prev, [bookingId]: false }));
      setConfirmingCancelId(null); // Reset confirming state
    } catch (error) {
      console.error('Error canceling booking:', error);
    }
  } else {
    // Mark as confirmed and start the timer
    setConfirmedStatus((prev) => ({ ...prev, [bookingId]: true }));
    const timer = setTimeout(() => {
      setConfirmedStatus((prev) => ({ ...prev, [bookingId]: false }));
      setConfirmingCancelId(null);
    }, 4000);
    setCancelTimer(timer);
  }
};


const [isModalOpen, setIsModalOpen] = useState(false);
const [confirmingArchiveId, setConfirmingArchiveId] = useState('');


const handleOpenModal = (bookingId) => {
  setConfirmingArchiveId(bookingId);
  setIsModalOpen(true);
};
const handleCloseModal = (bookingId) => {
  setConfirmingArchiveId(bookingId);
  setIsModalOpen(false);
};

const handleArchiveBooking = async (bookingId, rating, details) => {
  if (confirmingArchiveId === bookingId) {
    try {
      // Emit an event to the server with the booking ID and additional data
      socket.emit('archive-booking', { bookingId: confirmingArchiveId, driverRating: rating, hindsightDetails: details, userId: user.uid });
      // Reset the confirming state
      setConfirmingArchiveId(null);
      navigate('/');
    } catch (error) {
      console.error('Error archiving booking:', error);
    }
  }
};
const handleModalSubmit = (bookingId, rating, details) => {
 
    handleArchiveBooking(confirmingArchiveId, rating, details);

}

const handleDeleteClick = (bookingId) => {
  setConfirmingCancelId(bookingId);
  // Set a timer for 4 seconds to revert the icon back to the trash can
  const timer = setTimeout(() => {
    setConfirmingCancelId(null);
  }, 4000);
  setCancelTimer(timer);
};

const handleConfirmCancel = (bookingId) => {
  clearTimeout(cancelTimer); // Clear the timer
  handleCancelBooking(bookingId); // Call the cancel booking function
};
useEffect(() => {
  // Cleanup the timer when the component unmounts
  return () => {
    if (cancelTimer) {
      clearTimeout(cancelTimer);
    }
  };
}, [cancelTimer]);



useEffect(() => {
  const handleDriverCancel = ({ bookingId }) => {

    setBookings(prevBookings => {
      return prevBookings.map(booking => {
        if (booking._id === bookingId) {
          return { ...booking, status: 'available' };
        }
        return booking;
      });
    });
  };

  socket.on('driver-cancelled', handleDriverCancel);

  return () => {
    socket.off('driver-cancelled', handleDriverCancel);
  };
}, [socket]);


  useEffect(() => {
    const handleDriverLocationUpdate = ({ bookingId, newCoordinates }) => {
      setDriverLocation(newCoordinates);
    };
  
    socket.on('driverLocationUpdate', handleDriverLocationUpdate);
  
    return () => {
      socket.off('driverLocationUpdate', handleDriverLocationUpdate);
    };
  }, [socket]);

  useEffect(() => {
    const handleBookingStatusUpdate = ({ bookingId, status }) => {

      setBookings(prevBookings => {
        return prevBookings.map(booking => {
          if (booking._id === bookingId) {
            return { ...booking, status: status };
          }
          return booking;
        });
      });
    };
  
    socket.on('bookingStatusUpdate', handleBookingStatusUpdate);
    return () => {
      socket.off('bookingStatusUpdate', handleBookingStatusUpdate);
    };
  }, [socket]);
  useEffect(() => {
    const handleDriverSharedPhone = ({ bookingId, driverPhone }) => {

      setBookings(prevBookings => {
        return prevBookings.map(booking => {
          if (booking._id === bookingId) {
            return { ...booking, driverPhone: driverPhone };
          }
          return booking;
        });
      });
    };

    socket.on('driverSharedPhone', handleDriverSharedPhone);

    return () => {
      socket.off('driverSharedPhone', handleDriverSharedPhone);
    };
  }, [socket]);

  useEffect(() => {
    socket.on('rideCompletionUpdate', ({ bookingId, completed }) => {
  
      setBookings(prevBookings => {
        return prevBookings.map(booking => {
          if (booking._id === bookingId) {
            return { ...booking, isPast: true, completed: completed  };
          }
          return booking;
        });
      });
    });
  
    return () => {
      socket.off('rideCompletionUpdate');
    };
  }, [socket]);



//-----------------------------------------------------



//------------------------------------------------------


  // Function to group bookings by date
  const groupBookingsByDate = (bookingsArray) => {
    return bookingsArray.reduce((group, booking) => {
      const dateStr = new Date(booking.date).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' });
      if (!group[dateStr]) {
        group[dateStr] = [];
      }
      group[dateStr].push(booking);
      return group;
    }, {});
  };
  const groupedBookings = groupBookingsByDate(bookings);
  const dates = Object.keys(groupedBookings).sort((a, b) => new Date(a) - new Date(b));



  const [isBookingExpanded, setIsBookingExpanded] = useState(); // State to track the expanded/collapsed state
  const handleBookingExpandChange = (panel) => (event, isBookingExpanded) => {
    setIsBookingExpanded(isBookingExpanded ? panel : false);
  };



  const [anchorEl, setAnchorEl] = useState(null);
  const [currentEditBooking, setCurrentEditBooking] = useState(null); 
const handleEditClick = (booking) => (event) => {
  setAnchorEl(event.currentTarget);
  setCurrentEditBooking(booking); // Store the current booking
};

// Handler for closing the edit menu
const handleClose = () => {
  setAnchorEl(null);
  setCurrentEditBooking(null); // Clear the current booking
};

// Handlers for editing date and price
const handleEditDate = () => {
  navigate(`/editdate/${currentEditBooking._id}`);
  handleClose();
};

const handleEditPrice = () => {
  navigate(`/editprice/${currentEditBooking._id}/${currentEditBooking.pickupLocation.coordinates[0]},${currentEditBooking.pickupLocation.coordinates[1]}/${currentEditBooking.dropoffLocation.coordinates[0]},${currentEditBooking.dropoffLocation.coordinates[1]}`);
  handleClose();
};

const handleSignUpClick = () => {
  navigate(`/signup`);

};
const handleLoginClick = () => {
  navigate(`/login`);

};

const [expanded, setExpanded] = useState(false);
const handlePaymentOptionsClick = () => {
  setExpanded(!expanded);
};

useEffect(() => {
  const handleKeyboardShow = (event) => {
    const chatInput = document.querySelector('.chat-input');
    chatInput.style.position = 'absolute';
    chatInput.style.bottom = `${event.keyboardHeight}px`;
  };
  const handleKeyboardHide = () => {
    const chatInput = document.querySelector('.chat-input');
    chatInput.style.position = 'fixed';
    chatInput.style.bottom = '0';
  };
  if (Capacitor.isPluginAvailable('Keyboard')) {
    Keyboard.addListener('keyboardWillShow', handleKeyboardShow);
    Keyboard.addListener('keyboardWillHide', handleKeyboardHide);
  } else {
    console.log('Keyboard plugin is not available on web.');
  }
  return () => {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.removeAllListeners();
    }
  };
}, []);


const shimmer = keyframes`
0% {
  background-position: -40rem 0;
}
100% {
  background-position: 40rem 0;
}
`;

const ShimmerButton = styled(Button)({
  position: "relative",
  overflow: "hidden",
  fontWeight: "bold",
  fontSize: "1rem",
  color: "#FFFFFF",
  paddingTop:'10px',

  backgroundColor: "#234848",
  backgroundImage:
    "linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0.1) 75%, transparent 75%, transparent)",
  backgroundSize: "40rem 40rem",
  animation: `${shimmer} 37s linear infinite`,
  "&:hover": {
    backgroundColor: "#142929",
    boxShadow: "0 0 8px 0 rgba(255,255,255,0.3)",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "linear-gradient(to right, #0f9b8e, #234848)",
    opacity: 0.2,
  },
  "& .MuiButton-label": {
    position: "absolute",
    zIndex: 1200,
    textTransform: "none", // Prevents text from being all uppercase
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Modern, sleek, flat font
    fontWeight: 1100, // Medium font weight
    fontSize: "1rem", // Adjust font size as needed
  },
});
const CompletionShimmerButton = styled(Button)({
  position: "relative",
  overflow: "hidden",
  fontWeight: "bold",
  fontSize: "1rem",
  color: "#E9F2EC",
  paddingTop:'10px',

  backgroundColor: "#3E483A",
  backgroundImage:
    "linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0.1) 75%, transparent 75%, transparent)",
  backgroundSize: "40rem 40rem",
  animation: `${shimmer} 37s linear infinite`,
  "&:hover": {
    backgroundColor: "#182921",
    boxShadow: "0 0 8px 0 rgba(255,255,255,0.3)",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "linear-gradient(to right, #809B95, #474836)",
    opacity: 0.2,
  },
  "& .MuiButton-label": {
    position: "absolute",
    zIndex: 1200,
    textTransform: "none", // Prevents text from being all uppercase
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Modern, sleek, flat font
    fontWeight: 1100, // Medium font weight
    fontSize: "1rem", // Adjust font size as needed
  },
});
const LoginButton = styled(Button)({
  position: "relative",
  overflow: "hidden",
  fontWeight: "bold",
  fontSize: "1rem",
  color: "#FFFFFF",
  paddingTop:'9px',
  backgroundColor: "#8c8c8c",


  "& .MuiButton-label": {
    position: "absolute",
    zIndex: 1200,
    textTransform: "none", // Prevents text from being all uppercase
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Modern, sleek, flat font
    fontWeight: 1100, // Medium font weight
    fontSize: "1rem", // Adjust font size as needed
  },
});





if (loading) {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
}


    return (
      <div className="App">
        
        <CustomThemeProvider>
        <AppBar position="fixed" style={{ backgroundColor: '#001623', zIndex: 1200 }}>
          {user ? (

        
        <TopBar user={user} handleBookNow={handleBookNow} handleLogout={handleLogout} photoURL={userPhotoURL} />
      ) : (
        <TopBarBooking/>
      )}  </AppBar>

        <header className="App-header" style={{ paddingTop: '42px' }}>
          {user ? (
            <>

{showChat && (
     <div className="chat-container">
     <MainContainer style={{
       width: '100%',
       maxWidth: '650px',
       height: '62vh',
      bottom:'0%',
       backgroundColor: 'white',
       zIndex: 1201,
       display: 'flex',
       flexDirection: 'column',
     }}>
     
         <ConversationHeader>
         <Avatar
      name={driverUsername}
      src={driverPhotoURL}
    />
           <ConversationHeader.Content
             info={lastMessageTimeFormatted}
           
             userName={driverUsername}
           />
           <ConversationHeader.Back>
             <div onClick={() => {
               socket.emit('chat-closed', { bookingId: currentChatBookingId, userType: 'user' });
               setShowChat(false);
             }} style={{ cursor: 'pointer' }}>
               <ArrowDownward /> {/* This is the arrow-down icon */}
             </div>
           </ConversationHeader.Back>
         </ConversationHeader>
   
     
         <MessageList>
           {messages[currentChatBookingId] && Array.isArray(messages[currentChatBookingId]) && messages[currentChatBookingId].map((msg, index) => (
             <Message key={index} model={{
               message: msg.content,
               sentTime: new Date(msg.timestamp).toLocaleTimeString(),
               sender: msg.senderType,
               direction: msg.senderType === 'user' ? 'outgoing' : 'incoming',
               position: 'single'
             }} />
           ))}
         </MessageList>
    
      
         <MessageInput
           placeholder="Type message here"
           onSend={(text) => {
             socket.emit('send-message', { text, bookingId: currentChatBookingId, senderType: 'user' });
           }}
         />

     </MainContainer>
   </div>
)}
<Grid container justifyContent="center" style={{ marginTop: '70px', overflow: 'visible' }} className="bookings-container">
{dates.length > 0 ? (
  <Box style={{ maxWidth: '480px', width: '100%' }}>
    
{dates.map(date => (
        <Accordion
          key={date}
          defaultExpanded={true}

          onChange={handleBookingExpandChange(date)}
          style={{ width: '100%' }}
        >
<AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel1a-content"
  id="panel1a-header"
  style={{
    backgroundColor: '#E0E1E3'
  }}
>
  <Typography variant="subtitle1" component="div" style={{ margin: '0px 0' }}>
    {date}
  </Typography>
</AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column',backgroundColor:'#ecf1f1'}}>
          {groupedBookings[date].map((booking, index, array) => (
            <Box key={booking._id} style={{ marginBottom: "20px", backgroundColor:'#ecf1f1'}}>

      {/* Top section with route and map preview */}
      <Box display="flex" flexDirection="column" alignItems="center"style={{ marginBottom:"15px" }}>

    <Typography variant="subtitle1" color="text.alert" gutterBottom>
      <LocationOnIcon style={{ verticalAlign: 'middle', marginRight: 5 }} />
     <strong> {booking.pickupAddress+' '}</strong>→<span>{' '+booking.dropoffAddress}</span> 
      {/* Display trip distance */}
    <br />

    
      <span>
        <div dangerouslySetInnerHTML={{ __html: timeDisplay[booking._id] }} />
      </span>
    </Typography>
    {booking._id !== activeRide && booking.timeLeft && (
  <Typography style={{ color: '#B5504D' }}>
    Pickup scheduled for <strong>{booking.timeLeft}</strong> from now
  </Typography>
)}








{booking.isPast && booking.completed === 'fulfilled' &&(
 <Box style={{
  position:'absolute',
  marginTop:"140px",

  backdropFilter: 'blur(10px)', // Apply the blur effect
  backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white background
  padding: '10px', // Add some padding
  borderRadius: '5px', // Optional: if you want rounded corners
  zIndex:900
}}>     



<Typography style={{ color: '1F78BA' }}>
          <strong >Ride complete?</strong> 
          <br></br>
              <CompletionShimmerButton
      variant="contained"
      color="primary"
      size="medium"
      onClick={() => handleOpenModal(booking._id)}
      style={{ width: '110px' }} // Set a fixed width
      >
          <span>Rate & Archive</span>
  
      </CompletionShimmerButton>
      </Typography>
  
</Box>
      )}
     {booking.isPast && booking.completed === 'pending' &&(
 <Box style={{
  position:'absolute',
  marginTop:"140px",

  backdropFilter: 'blur(10px)', // Apply the blur effect
  backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white background
  padding: '10px', // Add some padding
  borderRadius: '5px', // Optional: if you want rounded corners
  zIndex:900
}}>
               <Typography style={{  fontSize: '14px' }}>
      <strong>No driver? Sorry about that! Please booking again</strong>
      <br></br>
      <CompletionShimmerButton
      variant="contained"
      color="primary"
      size="medium"
      onClick={() => confirmingCancelId === booking._id ? handleConfirmCancel(booking._id) : handleDeleteClick(booking._id)}
      style={{ width: '110px' }} // Set a fixed width
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3A4A5E'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#2E3B4E'}
      >
        {confirmingCancelId === booking._id ? (
          <CheckCircleIcon sx={{ color: '#B9BABA' }} />
        ) : (
          <span>Remove</span>
        )}
      </CompletionShimmerButton>
    </Typography>
</Box>
      )} 

{booking.isPast && booking.completed === 'no-show' &&(
 <Box style={{
  position:'absolute',
  marginTop:"140px",

  backdropFilter: 'blur(10px)', // Apply the blur effect
  backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white background
  padding: '10px', // Add some padding
  borderRadius: '5px', // Optional: if you want rounded corners
  zIndex:900
}}>
               <Typography style={{  fontSize: '14px' }}>
      <strong>No driver? Sorry about that! Please booking again</strong>
      <br></br>
      <CompletionShimmerButton
      variant="contained"
      color="primary"
      size="medium"
      onClick={() => confirmingCancelId === booking._id ? handleConfirmCancel(booking._id) : handleDeleteClick(booking._id)}
      style={{ width: '110px' }} // Set a fixed width
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3A4A5E'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#2E3B4E'}
      >
        {confirmingCancelId === booking._id ? (
          <CheckCircleIcon sx={{ color: '#B9BABA' }} />
        ) : (
          <span>Remove</span>
        )}
      </CompletionShimmerButton>
    </Typography>
</Box>
      )} 

<RateAndArchiveModal
  modalOpen={isModalOpen}
  onClose={handleCloseModal}
  onSubmit={(rating, details) => handleModalSubmit(booking._id, rating, details)}
/>




{booking.status === 'enrouteToDestination' && driverLocation ? (
  <TowardDestinationMap
    key={booking._id}
    bookingId={booking._id}
    dropoffCoordinates={booking.dropoffLocation.coordinates}
    driverLocation={driverLocation}
    size="220px"
  />
) : booking.status === 'arrivedAtPickup' && driverLocation  ? (
  <PickupArrivalMap
    key={booking._id}
    bookingId={booking._id}
    pickupCoordinates={booking.pickupLocation.coordinates}
    driverLocation={driverLocation}
    size="220px"
  />
) : booking.status === 'enrouteToPickup' && driverLocation ? (
  <DriverEnRouteMap
    key={booking._id}
    bookingId={booking._id}
    pickupCoordinates={booking.pickupLocation.coordinates}
    driverLocation={driverLocation}
    size="220px"
  />
) : (
  <MapPreview
    key={booking._id}
    pickupCoordinates={booking.pickupLocation.coordinates}
    dropoffCoordinates={booking.dropoffLocation.coordinates}
    onClick={() => navigate(`/EditLocations/${booking._id}`)}
    size="220px"
    focusOnPickup={!!booking.timeLeft}
  />
)}

  </Box>

  {booking.status === 'enrouteToPickup' && ( 
<Typography style={{ color: "#6B826D" }} >Driver is on their way!</Typography>
  )}
    {booking.status === 'arrivedAtPickup' && ( 
<Typography style={{ color: "#6B826D" }} >Driver has arrived!</Typography>
  )}
      <Divider style={{ margin: '10px 0' }} />




      <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: '20px' }}>
      <Box display="flex" justifyContent="center" alignItems="center" style={{ width: '42%' }}>
        <Box 
          backgroundColor="#90EBB4"
          borderRadius="5px"
          padding="8px 18px"
          boxShadow="0 0px 5px rgba(0, 16, 0, 0.2)"
          zIndex={0}
          position="relative"
        >

          
          {(booking.status !== 'accepted' && booking.status !== 'available' && booking.price && booking.venmo || booking.completed === 'fulfilled' && booking.price && booking.venmo) ? (
            
            <>
     <Typography variant="body2" style={{ fontSize: '19px', fontWeight: 'bold', color: 'black' }}>
            ${booking.price.toFixed(2)}
          </Typography>
              <Accordion expanded={expanded} onChange={handlePaymentOptionsClick}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    backgroundColor: '#20b09d',
                  color: 'black',
                  fontWeight: 'bold',
                  marginTop: '2px',
                  fontSize: '9px',
                  padding:'5px',
                
                  }}>
                  <Typography
                  style={{
                    backgroundColor: '#20b09d',
                  color: 'black',
                  fontWeight: 'bold',
                  marginTop: '2px',
                  fontSize: '9px',

                  }}
                  >
                    Pay driver online</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column"   style={{
                padding:'0px'

                  }} >
             
                    <Button
                      href={`https://account.venmo.com/payment-link?audience=public&amount=${booking.price}&recipients=${booking.venmo}`}
                      target="_blank"
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                    >
                      <img src="/venmo-icon.png" alt="Venmo" style={{ display: 'flex', alignItems: 'center', marginBottom: '4px',maxwidth: '20px'}}/>
                      <strong style={{  paddingLeft:'5px', fontSize:'10px'}}>Venmo</strong>
                    </Button>
                    <Divider></Divider>
                    <Button
                      href={`https://cash.me/${booking.cashapp}/${booking.price}`}
                     target="_blank"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                       <img src="/cashapp-icon.png" alt="Cashapp" style={{ display: 'flex', alignItems: 'center', marginBottom: '4px',maxwidth: '20px'}}/>
                      <strong style={{  paddingLeft:'5px', fontSize:'10px'}}>Cash App</strong>
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (booking.status === 'accepted' || booking.status === 'available') && (
  <Typography variant="body2" style={{ fontSize: '29px', fontWeight: 'bold', color: 'black' }}>
    ${booking.price.toFixed(2)}
  </Typography>
)}
          <Tooltip title="Pay in cash, Venmo or CashApp upon successful trip" placement="top" enterTouchDelay={0}>
            <InfoIcon style={{ position: 'absolute', top: '10%', right: '-3px', transform: 'translateY(-40%)', cursor: 'pointer' }} />
          </Tooltip>
        </Box>
    
</Box>

<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '44%' }}>
  {booking.status !== 'available' ? (
    <>
    <Typography variant="body1" style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold', fontSize: '8px', marginBottom: '-3px' }}>Driver:</span> <span style={{ fontWeight: 'bold' }}>{booking.driverUsername}</span>
            <Divider />
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center" style={{ position: 'relative', marginTop:'4px'}}>
            {/* <Typography variant="body2" style={{ fontWeight: 'bold',fontSize:'12px',marginRight: '30px' }}>
              {booking.driverVehicleTypeAndPlate}
            </Typography> */}
 <Tooltip
  title={
    <Box display="flex" flexDirection="column" alignItems="center" style={{ padding: '10px', backgroundColor: '#616968', color: '#fff', borderRadius: '8px' }}>
      <Avatar src={booking.driverPhotoURL} alt="Driver Photo" style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
      <Typography variant="body2" style={{ fontWeight: 'bold' }}>{booking.driverUsername}</Typography>
      <img src={booking.driverInfoPhotoURL} alt="Vehicle Silhouette" style={{ width: '124px', height: '61px', marginTop: '10px' }} />
      <Box display="flex" flexDirection="column" alignItems="center" style={{ marginTop: '10px' }}>
        <Typography variant="caption" style={{ fontWeight: 'bold', marginBottom: '-2px' }}>
          Plate:
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 'bold', backgroundColor: '#444', color: '#fff', padding: '5px', borderRadius: '4px' }}>
          {booking.driverVehicleTypeAndPlate.split(', #')[1]}
        </Typography>
      </Box>
      <Typography variant="body2" style={{ fontWeight: 'normal', marginTop: '5px' }}>
        {booking.driverVehicleTypeAndPlate.split(', #')[0]}
      </Typography>
    </Box>
  }
  arrow
>
  <IconButton
    onTouchMoveCapture={(event) => event.preventDefault()}
    style={{ position: 'relative', padding: '3px', border: '1px solid #AEB2B5', borderRadius: '14%', backgroundColor: '#E9EDED', boxShadow: '0px 4px 6px rgba(0.2, 0.2, 0.2, 0.2)' }}
  >
    <img src={booking.driverInfoPhotoURL} alt="Vehicle Silhouette" style={{ width: '80px', height: '41px', backgroundColor: '#E9EDED', pointerEvents: 'none' }} draggable="false" />
    <InfoIcon sx={{ fontSize: '16px', marginTop: '-10px' }} />
  </IconButton>
</Tooltip>
          </Box>



      <Box display="flex" alignItems="center">
      <Button
        variant="contained"
        onClick={() => handleToggleChat(booking._id)}
        startIcon={<MessageIcon />}
        style={{ boxShadow: "0 0px 5px rgba(0, 16, 0, 0.2)", margin: '5px 0', fontSize:'9px'}} // Adjusted margin for spacing
      >
        Chat {unreadCounts[booking._id] > 0 && (
          <strong style={{ color: '#BA4949' }}>
            ({unreadCounts[booking._id]})
          </strong>
        )}
      </Button>


  {/* Call button */}
  {booking.driverPhone && (
  <IconButton
  aria-label="call driver"
  onClick={() => window.open(`tel:${booking.driverPhone}`)}

>
  <CallIcon />
</IconButton>
  )}

</Box>
    </>
  ) : (
    <Typography variant="body2" style={{ fontWeight: 'bold', textAlign: 'center', fontSize:'12px', padding:'4px'}}>
              You'll be notified when a driver accepts
            </Typography>
  )
  }
</Box>
  {/* Vertical Divider */}
  <Box style={{ marginRight:'12px',width: '1px', backgroundColor: 'grey', alignSelf: 'stretch' }} />

  {/* Edit and Cancel buttons pinned to the right */}
  <Box key={booking._id} display="flex" flexDirection="column" alignItems="flex-end" style={{ width: '10%' }}>
  <IconButton onClick={(event) => handleEditClick(booking)(event)} style={{ boxShadow: "0 0px 3px rgba(0, 7, 0, 0.2)" }}>
    <EditIcon />
  </IconButton>
  <Menu
    id="edit-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl) && currentEditBooking?._id === booking._id}
    onClose={handleClose}
  >
    <MenuItem onClick={handleEditDate}>
      <EventIcon fontSize="small" style={{ marginRight: '5px' }} /> Edit Date
    </MenuItem>
    <MenuItem onClick={handleEditPrice}>
      <AttachMoneyIcon fontSize="small" style={{ marginRight: '5px' }} /> Edit Price
    </MenuItem>
  </Menu>
          <IconButton
            onClick={() => confirmingCancelId === booking._id ? handleConfirmCancel(booking._id) : handleDeleteClick(booking._id)}
            color="error"
            style={{ marginTop: "8px", boxShadow: "0 0px 3px rgba(0, 16, 0, 0.2)" }}
          >
            {confirmingCancelId === booking._id ? <CheckCircleIcon /> : <DeleteIcon />}
          </IconButton>
        </Box>
   
</Box>
{index < array.length - 1 && (
    <Box
      sx={{
        height: '25px', // Make the divider 1px high for a subtle line
        bgcolor: '#F2F2F2', // Set background color to transparent
        my: 3, // Margin for spacing above and below the divider
        boxShadow: 'inset 0px 1px 3px 1px #A1A5AB', // Lighter, indented shadow for the gutter effect
        width: 'calc(100% + 30px)', // Adjust the width to counteract the padding on the sides
        mx: '-15px', // Negative margin to align with the card edges
   
        borderBttom: "double"
      }}
    />
  )}
</Box>
        ))}
        </AccordionDetails>
      </Accordion>
    ))}

      </Box>

) : (

     <Box style={{ maxWidth: '480px', width: '100%', textAlign: 'center', marginTop:'80px'}}>

 <Container maxWidth="sm" sx={{ py: 2, marginTop:'-30px' }}>
        <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#dadbdb' }}>
          <CardContent>
            
            {bookingLoading ? ( 
              <Box sx={{height:'100%', width:'100%'}}>
              <div className="spinner-container-bookings">
              <div className="spinner"></div>
            </div>
            </Box>
            ) : (
              <div>
                    <Typography variant="h6" component="h2" sx={{ }}>
                    Dashboard
                  </Typography>
                  <Box style={{ maxWidth: '480px', width: '100%' }}>
                  <Typography variant="subtitle1" sx={{ paddingBottom:'5px', color:'#656766'}}>
           Your rides will show up here. 
          </Typography>
                  </Box>
                  </div>
            )}
      
          </CardContent>
        </Card>
      </Container>
 <img src="no-booking-pic6.png" alt="Prompt to book" style={{ maxWidth: '100%', height: 'auto' }} />
  </Box>
  )}
  
  </Grid>
            </>
              ) : (
           
   <>
<Box sx={{ 
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  left: 0,
  right: 0,
  position: 'absolute',
  zIndex: '1300',
  bottom: 0,
  top: 0,
  backgroundColor: '#02171F'
}}>
  <img src="/splash.png" alt="Demo" style={{
    height: '100vh',
    width: 'auto',
    maxWidth: '100vw',
    objectFit: 'contain',
    position: 'absolute',
    top: 0,
  }} />

  <Box sx={{ 
    position: 'absolute',
    top: '74%',
    zIndex: '1302',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}><Box sx={{}}> <a href="https://apps.apple.com/us/app/branson-rideshare/id6505027882" target="_blank"><img  src="/app-store.svg" alt="Download on the App Store" sx={{ height:'50px',}}/></a></Box><Divider sx={{width:'80%', backgroundColor:'white',height:'1px', margin:'8px'}}></Divider>
    <Box sx={{ display: 'flex', gap: '6%' }}>
      <ShimmerButton
        variant="contained"
        onClick={handleSignUpClick}
        sx={{ width: '94px', fontSize: '12px', height: '30px' }}
      >
        Sign Up
      </ShimmerButton>
    
      <LoginButton
        sx={{ backgroundColor: '#878280', width: '94px', fontSize: '12px', height: '30px' }}
        color="primary"
        onClick={handleLoginClick}
      >
        Log In
      </LoginButton>
    </Box>
    <Typography variant="subtitle3" gutterBottom sx={{ color: '#E9EDEB', margin: '18px', width: '310px', textAlign: 'center' }}>
      Sign up, ride requests and cancellations are completely free
    </Typography>
  </Box>
</Box>


            </>
          )}
        </header>
        <Box style={{alignItems:'center', margin:"2px",marginTop:'900px' }}>

<Link to="privacypolicy" relative="path"style={{color:"#DDDEDD" }}>
      Privacy Policy
    </Link><br></br>
<Typography variant="subtitle4" style={{ color: '#B4C3C1', textAlign: 'center', zIndex:"1",marginTop:"-20px"}}>
© Mark Forsythe. All Rights Reserved.
      </Typography>
</Box><br></br>
        </CustomThemeProvider>
      </div>
    );
  
}
  export default App;





