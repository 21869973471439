import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import axios from 'axios'; // Now we're using axios
import { Container, Card, CardContent, Typography, TextField, Button, AppBar, Box, Tooltip,  IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper  } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomThemeProvider } from '../theme';
import TopBar from '../components/TopBar';
import '../App.css';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Star, StarBorder } from '@mui/icons-material';
axios.defaults.withCredentials = true;
const TripHistory = () => {
  const navigate = useNavigate();

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("America/Chicago");
  const socket = io('https://bransonrideshare.com', {
    transports: ['websocket', 'polling'],
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
  });

 const [archivedBookings, setArchivedBookings] = useState([]);

 const [user, setUser] = useState(null);

 const [loading, setLoading] = useState(true);
 const [authChecked, setAuthChecked] = useState(false);
 const [username, setUsername] = useState(null);
const [userPhotoURL, setUserPhotoURL] = useState(null);
const [photoURL, setPhotoURL] = useState('');

useEffect(() => {
  const checkAuthStatus = async () => {
    try {
      const result = await FirebaseAuthentication.getIdToken();
      const token = result.token;

      if (token) {
        const response = await axios.get(`https://bransonrideshare.com/user-verify-token`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.valid) {
          setUser({ uid: response.data.userId });
          setUsername(response.data.username || '');
          setUserPhotoURL(response.data.photoURL);
          setAuthChecked(true);

        
        } else {
          localStorage.removeItem('userToken');
          setUser(null);
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      setUser(null);
    }
    setLoading(false);
  };

  checkAuthStatus();
}, [authChecked]);

 
useEffect(() => {
  if (user) {
    const requestArchivedBookings = () => {
      socket.emit('request-archived-bookings', { userId: user.uid });
    };

    socket.on('archived-bookings-data', (data) => {
      setArchivedBookings(data);
    });

    socket.on('bookings-error', (message) => {
      console.error('Error fetching archived bookings:', message);
    });

    requestArchivedBookings();

    return () => {
      socket.off('archived-bookings-data');
      socket.off('bookings-error');
    };
  }
}, [user]);



const formatDate = (date) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return new Date(date).toLocaleString(undefined, options);
};

const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(i <= rating ? <Star key={i} style={{ color: '#FFD700', fontSize: '16px' }} /> : <StarBorder key={i} style={{ color: '#FFD700', fontSize: '16px' }} />);
  }
  return <Box display="flex">{stars}</Box>;
};

const handleBookNow = () => {
  localStorage.setItem('user', JSON.stringify({
    uid: user.uid,
    displayName: user.username,
    photoURL: user.photoURL,

  }));

  navigate('/selectdate/'+user.uid);
};
const handleBack = () => {
  navigate(`/`);
};

return (
  <CustomThemeProvider>
  <AppBar position="fixed" style={{ backgroundColor: '#001623', zIndex: 1200 }}>
  <TopBar user={user} handleBookNow={handleBookNow}  photoURL={userPhotoURL} />
    </AppBar>
    <Container component="main" maxWidth="md"         
      style={{
        backgroundColor: '#E0E1E3'
      }}>
      <Box sx={{ 
        mt: 24, 
        p: 2,
    
        }}>
        <Typography component="h4" variant="h6" sx={{ marginLeft: '-100px' }}>
          <IconButton
            size="small"
            onClick={handleBack}
            id="back-booking"
            position="end"
            sx={{ marginRight: '50px' }}
          >
            <ArrowBackIcon />
          </IconButton>
          Trip History
        </Typography>
        {archivedBookings.map((booking, index) => (
          <Card key={index} sx={{ 
            mt: 2,
                backgroundColor:'#EEF2E9'

           }}>
            <CardContent    sx={{ 
          
                backgroundColor:'#EEF0F2'

           }}>
              <Typography variant="h6">{formatDate(booking.date)}</Typography>
              <Typography variant="body2">
                <strong>Pickup:</strong> {booking.pickupAddress}
              </Typography>
              <Typography variant="body2">
                <strong>Dropoff:</strong> {booking.dropoffAddress}
              </Typography>
              <Typography variant="body2">
                <strong>Driver:</strong> {booking.driverName}
              </Typography>
              <Typography variant="body2">
                <strong>Price:</strong> ${Math.round(booking.price)}
              </Typography>
              <Typography variant="body2">
                {renderStars(booking.driverRating)}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  </CustomThemeProvider>
  );

}

  export default TripHistory;
            
