import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a theme instance with your color palette
const theme = createTheme({
    
  palette: {
    grey: {
      100: "#e0e0e0",
      200: "#c2c2c2",
      300: "#a3a3a3",
      400: "#858585",
      500: "#666666",
      600: "#525252",
      700: "#3d3d3d",
      800: "#292929",
      900: "#141414"
    },
    primary: {
      main: "#141b2d", // Use the 500 value for the main color
      // ... include other shades if necessary
    },
    secondary: {
      main: "#4cceac", // Use the 500 value for the main color
      // ... include other shades if necessary
    },
    error: {
      main: "#db4f4a", // Use the 500 value for the main color
      // ... include other shades if necessary
    },
    info: {
      main: "#2B4A46", // Use the 500 value for the main color
      // ... originally:   323B2A
      typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      }
    },
    review: {
        main: "#a5a461", // Use the 500 value for the main color
        // ... include other shades if necessary
      },
      messageBtn: {
        main: "#70A8C7", // Use the 500 value for the main color
        // ... include other shades if necessary
      },
    // ... include other colors if necessary
  },
  overrides: {
    MuiAccordionSummary: {
      root: {
        padding: '0px 16px',
        minHeight: '10px',
        '&.Mui-expanded': {
          minHeight: '0px',
        },
      },
      content: {
        margin: '0px', // Adjust as needed
        '&.Mui-expanded': {
          margin: '0px', // Adjust as needed
        },
      },
    },
  },

});

// Export the ThemeProvider with the theme
export function CustomThemeProvider({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}