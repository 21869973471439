import React, {useEffect, useState } from 'react';
import { useNavigate, userCredential } from 'react-router-dom';
import axios from 'axios';
import { Card, CardContent, TextField, Button, Typography, Container, Divider, styled} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TopBarBooking from '../components/TopBarBooking'; // Assuming you have a TopBarBooking component
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import '../App.css';



axios.defaults.withCredentials = true;
const VerifyEmail = () => {
  // const axiosInstance = axios.create({
  //   // baseURL: 'http://localhost:5005', 
  //     baseURL: 'https://bransonrideshare.com', 
  //     withCredentials: true 
  //   });

  const navigate = useNavigate();


  const [email, setEmail] = useState('');

  useEffect(() => {
    const savedEmail = localStorage.getItem('emailForSignUp');
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);


  const handleLoginClick = () => {
    navigate(`/login`);
  
  };

  const LoginButton = styled(Button)({
    position: "relative",
    overflow: "hidden",
    fontWeight: "bold",
    fontSize: "1rem",
    color: "#FFFFFF",
    paddingTop:'9px',
    backgroundColor: "#8c8c8c",
  
  
    "& .MuiButton-label": {
      position: "absolute",
      zIndex: 1200,
      textTransform: "none", // Prevents text from being all uppercase
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Modern, sleek, flat font
      fontWeight: 1100, // Medium font weight
      fontSize: "1rem", // Adjust font size as needed
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <TopBarBooking />
      <Card sx={{ mt: 24, p: 1, backgroundColor: '#e2e3e0', }}>
        <CardContent sx={{ marginTop: '3px',backgroundColor: '#e2e3e0', }}>
        <Typography component="subtitle6" variant="subtlte7" align="left">
     
    <strong>A verification email has been sent to your email, please confirm before logging in. </strong>

    <br></br>
  </Typography>

  <br></br>

  <LoginButton sx={{backgroundColor:'#70a997'}}
  id="loginBtn"
  variant="contained"
  color="primary"
  size="medium"
  onClick={handleLoginClick}
  style={{ width: '110px' }} // Use the same fixed width
>
  Log In
</LoginButton>
<br></br>
<Typography sx={{fontSize:'14px', marginTop:'13px'}}>Hint: Can't find? Check spam folder</Typography>


        </CardContent>
      </Card>
    </Container>
  );
};

export default VerifyEmail;