import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Container, Card, CardContent, Typography, Box, IconButton, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TopBarBooking from '../components/TopBarBooking'; // Assuming you have a TopBarBooking component
import '../App.css';
const About = () => {
 
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/`);
  };

  return (
    <Container component="main" maxWidth="xs">
      <TopBarBooking />
      <Card sx={{ mt: 24  }}>
        <CardContent>
        <Typography component="h6" variant="h6" sx={{margin:'10px'}}>
            <Typography component="h1" variant="h5" align="left">
    <IconButton size="small" onClick={handleBack} sx={{ position: 'absolute', width: '14%', }}>
      <ArrowBackIcon />
    </IconButton>
    </Typography>
   
    <strong>About</strong>
 
            </Typography>
          <br /><br />
          <Divider></Divider>
          <Typography   >
          <h3 class="gradient-text">Now available in the Branson, MO area!</h3>
     
          </Typography>
        <Divider></Divider> <br></br>
          <Typography component="subtitle6" variant="subtitle3" sx={{fontSize:'14px'}}  >
          New cash-based rideshare solution for the Branson, Missouri area. Built and operated by a local rideshare driver. Service is in beta, if you have any suggestions please don't hesitate to email us at <a href="mailto:bransonrideshare@gmail.com">bransonrideshare@gmail.com</a>
          </Typography>
          <br></br><br></br>
          <Typography component="subtitle6" variant="subtitle3" sx={{fontSize:'14px'}}  >
          We aim to be the local rideshare solution that customers can respect for our transparency, integrity and hospitality. 
       </Typography>
          <Divider sx={{marginTop:'5px'}}></Divider>
          <br></br>
          <Typography component="subtitle6" variant="subtitle3"   >
            Sign up, booking, cancellation are all free, please feel free to request and cancel rides as you wish.<br></br> Our only request is to be curteous of our drivers' time (<u>e.g. try to avoid cancelling a ride once driver is on the way</u>) so that we can keep the service flexible.
          </Typography>

          <br /><br />   </CardContent>
          </Card>    <Card sx={{ mt: 2, p: 2 }}>
          <CardContent>
          <Typography component="h6" variant="h6">
           <b> Current Drivers:</b>
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
       
                </TableRow>
              </TableHead>
              <TableBody>
                
                  <TableRow>
                    <TableCell>
                    <img src="../driver-preview.png" alt="Driver preview" style={{
            width: '100%', // Adjust width as needed
            maxWidth: '470px', // Adjust maximum width as needed
          }} />
                    </TableCell>
          
                  </TableRow>
                      
       
              </TableBody>
            </Table>
          </TableContainer>
                  
         
        </CardContent> 
      </Card>
    </Container>
  );
};

export default About;