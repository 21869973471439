import React from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom'; // Remove this line
import { createRoot } from 'react-dom/client'; // Update this line

import './index.css';
import App from './App';
import About from './pages/About'; // Import the SelectDate component
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import the SignUp component
import SignUp from './pages/SignUp'; // Import the SignUp component
import VerifyEmail from './pages/VerifyEmail'; // Import the SignUp component
import Login from './pages/Login'; // Import the SignUp component
import SelectDate from './components/booking/SelectDate'; // Import the SelectDate component
import SelectLocations from './components/booking/SelectLocations'; // Import the SelectLocations component
import SetPrice from './components/booking/SetPrice'; // Import the SetPrice component
import Confirmation from './components/booking/Confirmation'; // Import the Confirmation component
import EditPrice from './components/booking/EditPrice'; // Import the SelectDate component
import EditDate from './components/booking/EditDate'; // Import the SelectDate component
import TripHistory from './pages/TripHistory'; // Import the SelectDate component
import Account from './pages/Account'; // Import the SelectDate component

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import necessary components from react-router-dom
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root')); // Update this line
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={< About />} />
        <Route path="/privacypolicy" element={< PrivacyPolicy />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route path="/login" element={<Login />} />
        <Route path="/triphistory" element={<TripHistory />} />
        <Route path="/account" element={<Account />} />

        <Route path="/selectdate/:username" element={<SelectDate />} />
        <Route path="/selectlocations/:username/:datetime" element={<SelectLocations />} />
        <Route path="/setprice/:username/:datetime/:pickupCoordinates/:dropoffCoordinates/:pickupAddress/:dropoffAddress/" element={<SetPrice />} />
        <Route path="/confirmation/:username/:datetime/:pickupCoordinates/:dropoffCoordinates/:pickupAddress/:dropoffAddress/:price" element={<Confirmation />} />
        <Route path="/editdate/:bookingId" element={<EditDate />} />
        <Route path="/editprice/:bookingId/:pickupCoordinates/:dropoffCoordinates" element={<EditPrice />} />

        {/* Define other routes here */}
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();