import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CapacitorHttp } from '@capacitor/core';
import { Card, CardContent, TextField, Button, Typography, Container, IconButton, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
//import CarIcon from '@mui/icons-material/CarIcon';
import TopBarBooking from '../components/TopBarBooking'; // Assuming you have a TopBarBooking component
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

import '../App.css';
import LocalTaxiSharpIcon from '@mui/icons-material/LocalTaxiSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';


import io from 'socket.io-client';

const socket = io('https://bransonrideshare.com', {
  transports: ['websocket', 'polling'],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
});

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    socket.on('emailCheck', (data) => {
      setEmailError(data.error);
    });

    socket.on('usernameCheck', (data) => {
      setUsernameError(data.error);
    });

    return () => {
      socket.off('emailCheck');
      socket.off('usernameCheck');
    };
  }, []);
  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      setIsSubmitting(false);
      return;
    }
  
    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters long.');
      setIsSubmitting(false);
      return;
    }
  
    try {
      const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
        email,
        password,
      });
      const user = result.user;
      console.log('User created:', user);
  
      await FirebaseAuthentication.sendEmailVerification();
      console.log('Verification email sent');
  
      const tokenResult = await FirebaseAuthentication.getIdToken();
      const token = tokenResult.token;
      console.log('ID Token:', token);
  
      const options = {
        url: 'https://bransonrideshare.com/save-username',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: {
          uid: user.uid,
          username,
          email
        }
      };
      
      const response = await CapacitorHttp.post(options);
  
      console.log('Server response:', response.data);
  
      // Save email to localStorage
      localStorage.setItem('emailForSignUp', email);
  
      setTimeout(() => {
        navigate('/verifyemail');
        setIsSubmitting(false);
      }, 500);
    } catch (error) {
      console.error('Signup failed', error);
      setIsSubmitting(false);
  
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Signup failed: ${error.response.data.error}`);
      } else if (error.code === 'auth/email-already-in-use') {
        setEmailError('The email address is already in use by another account.');
      } else if (error.code === 'auth/invalid-email') {
        setEmailError('The email address is not valid.');
      } else if (error.code === 'auth/weak-password') {
        setPasswordError('The password is too weak.');
      } else {
        alert(`Signup failed. Please try again. Error code: ${error.code}, message: ${error.message}`);
      }
    }
  };

  const handleBack = () => {
    navigate(`/`);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    socket.emit('checkEmail', e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    socket.emit('checkUsername', e.target.value);
  };

  return (
    <Container component="main" maxWidth="xs">
      <TopBarBooking />
      <Card sx={{ mt: 24, p: 2, backgroundColor: '#e2e3e0', backgroundSize: 'cover' }}>
        <CardContent>
          <Typography component="h6" variant="h6" sx={{ margin: '10px' }}>
            <Typography component="h1" variant="h5" align="left">
              <IconButton size="small" onClick={handleBack} sx={{ position: 'absolute', width: '14%' }}>
                <ArrowBackIcon />
              </IconButton>
            </Typography>
            <strong>Sign Up</strong>
          </Typography>
          <form onSubmit={handleSignUp}>
            <Typography align="left" sx={{ fontSize: '12px', marginTop: "1px", marginLeft: "5px" }}>Email / Username</Typography>
            <TextField
              margin="dense"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
              sx={{
                marginTop: '-2px',
                '& .MuiInputBase-input': {
                  borderRadius: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  padding: '12px 12px',
                  border: '1px solid #ced4da',
                  fontSize: '16px',
                },
              }}
              placeholder="Email"
            />
            <Divider sx={{ marginTop: '-2px', }}></Divider>
            <TextField
              margin="dense"
              required
              fullWidth
              id="username"
              name="username"
              autoComplete="username"
              value={username}
              onChange={handleUsernameChange}
              error={!!usernameError}
              helperText={usernameError}
              sx={{
                marginTop: '-1px',
                '& .MuiInputBase-input': {
                  borderRadius: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  padding: '12px 12px',
                  border: '1px solid #ced4da',
                  fontSize: '16px',
                },
              }}
              placeholder="Username"
            />
            <Divider sx={{ marginTop: '5px' }}></Divider>
            <Typography align="left" sx={{ fontSize: '12px', marginTop: "7px", marginLeft: "5px" }}>Password</Typography>
            <TextField
              margin="dense"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!passwordError}
              helperText={passwordError}
              sx={{
                marginTop: "-1px",
                '& .MuiInputBase-input': {
                  borderRadius: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  padding: '12px 12px',
                  border: '1px solid #ced4da',
                  fontSize: '16px',
                },
              }}
              placeholder="Password"
            />
            <TextField
              margin="dense"
              required
              fullWidth
              name="confirmPassword"
              type="password"
              id="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={!!passwordError}
              helperText={passwordError}
              sx={{
                marginTop: '-5px',
                '& .MuiInputBase-input': {
                  borderRadius: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  padding: '12px 12px',
                  border: '1px solid #ced4da',
                  fontSize: '16px',
                },
              }}
              placeholder="Confirm password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                width: '70%',
                mt: 1,
                mb: 1,
                backgroundColor: "#717a68",
                borderRadius: 0,
                fontSize: '46px',
                padding: '15px 10px',
              }}
              disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={30} style={{ color: 'white', padding: '15px' }} /> :
                <CheckCircleSharpIcon sx={{ fontSize: '50px' }} />}
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SignUp;