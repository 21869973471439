import React, { useState, useEffect, useRef } from 'react';
import maplibre from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import {  Button, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import pickupIconUrl from '../user-hand-up.svg'; 
import dropoffIconUrl from '../car.svg'; 


const MapPreview = ({ pickupCoordinates, dropoffCoordinates, size, focusOnPickup }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);



  useEffect(() => {
    if (map.current) return; // Initialize map only once
    map.current = new maplibre.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/5e93952d-a638-4302-8ab2-bab246582ff4/style.json?key=UHHUx3iqEIPjCVMQT4MD`,
      center: pickupCoordinates, // Center the map on the pickup location
      zoom: focusOnPickup ? 15 : 12 // Zoom in if focusing on pickup
    });
  
    map.current.on('load', () => {
      const osrmRouteUrl = `https://router.project-osrm.org/route/v1/driving/${pickupCoordinates[0]},${pickupCoordinates[1]};${dropoffCoordinates[0]},${dropoffCoordinates[1]}?overview=full&geometries=geojson`;
  
      fetch(osrmRouteUrl)
        .then(response => response.json())
        .then(data => {
          const route = data.routes[0].geometry;
  
          map.current.addSource('route', {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: route
            }
          });
  
          map.current.addLayer({
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': '#5D698A', // Dark greyish maroon color
              'line-width': 8
            }
          });

          // Show custom icon before booking time for pickup spot
          //----------------------------------------------------------
         

          //-----------------------------------------------------------

  
          new maplibre.Marker({ color: "#19B880" })
            .setLngLat(dropoffCoordinates)
            .addTo(map.current);
  
          // Adjust the map view and pickup icon based on focusOnPickup
          if (focusOnPickup) {
            new maplibre.Marker({ 
              element: createCustomMarkerElement(pickupIconUrl),
              anchor: 'bottom' // Adjust the anchor point if needed
             })
            .setLngLat(pickupCoordinates)
            .addTo(map.current);
  
            // If focusing on pickup, just center the map on the pickup coordinates
            map.current.flyTo({
              center: pickupCoordinates,
              zoom: 15, // Zoom level when focusing on pickup
              essential: true // This animation is considered essential with respect to prefers-reduced-motion
            });
          } else {
            new maplibre.Marker({ color: "#71948E" })
            .setLngLat(pickupCoordinates)
            .addTo(map.current);
  
            // If not focusing on pickup, fit the map to the bounds of the route
            const bounds = new maplibre.LngLatBounds(route.coordinates[0], route.coordinates[0]);
            route.coordinates.forEach(coord => bounds.extend(coord));
            map.current.fitBounds(bounds, {
              padding: 33,
              duration: 600
            });
          }
        })
        .catch(error => console.error('Error fetching route:', error));
    });
  }, [pickupCoordinates, dropoffCoordinates, focusOnPickup]);


  function createCustomMarkerElement(pickupIconUrl) {
    const el = document.createElement('div');
    el.className = 'custom-marker';
    el.style.backgroundImage = `url(${pickupIconUrl})`;
    el.style.width = '35px'; // Set the width of the icon
    el.style.height = '35px'; // Set the height of the icon
    el.style.backgroundSize = 'cover';
 
    return el;
  }


  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (map.current) {
      map.current.resize(); // Ensure the map adjusts to the new size
      if (!isFullScreen) {
        // Enable map interaction
        map.current.boxZoom.enable();
        map.current.scrollZoom.enable();
        map.current.dragPan.enable();
        map.current.dragRotate.enable();
        map.current.keyboard.enable();
        map.current.doubleClickZoom.enable();
        map.current.touchZoomRotate.enable();
      } else {
        // Disable map interaction
        map.current.boxZoom.disable();
        map.current.scrollZoom.disable();
        map.current.dragPan.disable();
        map.current.dragRotate.disable();
        map.current.keyboard.disable();
        map.current.doubleClickZoom.disable();
        map.current.touchZoomRotate.disable();
      }
    }
  };

  // Disable map interaction initially
  useEffect(() => {
    if (map.current) {
      map.current.boxZoom.disable();
      map.current.scrollZoom.disable();
      map.current.dragPan.disable();
      map.current.dragRotate.disable();
      map.current.keyboard.disable();
      map.current.doubleClickZoom.disable();
      map.current.touchZoomRotate.disable();
    }
  }, []);
  return (
    <div ref={mapContainer} className={`map-container ${isFullScreen ? 'full-screen' : ''}`} style={{ width: '100%', height: isFullScreen ? '100vh' : size }}>
      <IconButton onClick={toggleFullScreen} style={{ 
        position: 'absolute', 
        top: isFullScreen ? 60 : 10,
        right: 10, 
        zIndex: isFullScreen ? 1100 : 1, // Ensure the button is above the top bar when expanded
        fontSize: '32px' // Adjust the font size as needed
      }}>
        {isFullScreen ? <FullscreenExitIcon fontSize="inherit" /> : <FullscreenIcon fontSize="inherit" />}
      </IconButton>
    </div>
  );
};

export default MapPreview;