import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CustomThemeProvider } from '../../theme';
import TopBar from '../../components/TopBar';
import { Container, Card, CardContent, Typography, Button, TextField, IconButton, Divider} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import io from 'socket.io-client';

const EditDate = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("America/Chicago");
  const navigate = useNavigate();
  const { bookingId } = useParams(); // Use bookingId instead of username

  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Connect to the socket server
    const newSocket = io('https://bransonrideshare.com', {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
    });
    setSocket(newSocket);

    // Clean up on component unmount
    return () => newSocket.close();
  }, [setSocket]);

  const handleDateTimeChange = (newValue) => {
    setSelectedDateTime(newValue);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleUpdateBookingDate = (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Start the loading state
    const utcDateString = selectedDateTime.utc().toISOString();
    // Emit the updateBookingDate event with the bookingId and new date
    socket.emit('updateBookingDate', { bookingId, newDateTime: utcDateString });

  };

  // Listen for the bookingDateUpdated event to navigate to a confirmation page or similar
  useEffect(() => {
    if (socket) {
      socket.on('bookingDateUpdated', (data) => {
        // Handle the updated booking date here
        // For example, navigate to a confirmation page
        setTimeout(() => {
          navigate('/');
          setIsSubmitting(false); // Reset the isSubmitting state after navigation
        }, 1500);
      });

      socket.on('errorUpdatingBooking', (errorMessage) => {
        // Handle any errors here
        console.error(errorMessage);
      });
    }
  }, [socket, navigate]);
  const handleBack = () => {
    // Logic to validate pickup address and fetch coordinates
    navigate(`/`);
  };

  return (
    <CustomThemeProvider>
      <TopBar />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container maxWidth="sm" sx={{ py: 4, mt: 10 }}>
          <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff', marginTop:'30px'  }}>
          <CardContent sx={{ px: 6, py: 6, mb:2 }}>
            <Typography component="h4" variant="h5" align="center" sx={{fontSize:'17px'}}>
   
    <strong>New date & time update:</strong>
   
  </Typography>
  <Divider></Divider>
              <br></br>
              <MobileDateTimePicker sx={{ width: "70%",  '& .MuiInputBase-input': {
     
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // added this line
          padding: '12px 12px',
          border: '1px solid #ced4da',
          width:'100%',

        
        
        }, }}
                value={selectedDateTime}
                onChange={handleDateTimeChange}
                renderInput={(params) => <TextField {...params} />}
              /> <br></br>     <Button variant="contained" color="primary" onClick={handleUpdateBookingDate} sx={{  fontSize: 17, px: 2, py: 1, width: '90%', marginTop: "9px", color:"#dee6e1" }}>
              {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Update'}<ArrowForwardIcon sx={{fontSize:'30px'}}/>
            </Button>
          
            </CardContent>
          </Card>
        </Container>
      </LocalizationProvider>
    </CustomThemeProvider>
  );
};

export default EditDate;