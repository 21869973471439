import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Card, CardContent, Typography, TextField, Button, Stack, Slide, IconButton, InputAdornment, AppBar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import maplibregl from 'maplibre-gl';
import MapPreview from '../MapPreview';
import moment from 'moment';
import { CustomThemeProvider } from '../../theme';
import TopBarBooking from '../TopBarBooking';
import '../../App.css';
import dayjs from 'dayjs';
import TopBar from '../TopBar';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
const SetLocations = () => {
    const navigate = useNavigate();
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault("America/Chicago");
  const { username, datetime } = useParams();

  const [pickupAddress, setPickupAddress] = useState('');
  const [dropoffAddress, setDropoffAddress] = useState('');
  const [pickupCoordinates, setPickupCoordinates] = useState(null);
  const [dropoffCoordinates, setDropoffCoordinates] = useState(null);
  const [isDropoffEnabled, setIsDropoffEnabled] = useState(false);
  const [map, setMap] = useState(null);
  const [routePreviewed, setRoutePreviewed] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    // Initialize MapLibre map
    const map = new maplibregl.Map({
      container: 'map', // container ID
      style: `https://api.maptiler.com/maps/5e93952d-a638-4302-8ab2-bab246582ff4/style.json?key=UHHUx3iqEIPjCVMQT4MD`,
      center: [ -93.2185144, 36.6436731 ], // starting position [lng, lat]
      zoom: 11 // starting zoom
    });
    
    setMap(map);
// Load the Google Maps script
const loadGoogleMapsScript = () => {
  if (typeof window.google === 'undefined') {
    // Check if the script tag already exists
    const existingScript = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDQKaUXdwffAC8oXg4oRmBGMxqh7kiNMaM&libraries=places&callback=initAutocomplete`;
      script.async = true; // This ensures the script is loaded asynchronously
      document.body.appendChild(script);
    }
  } else {
    window.initAutocomplete(); // Call this function once the script is loaded
  }
};

// Make sure to call this function when your component mounts
loadGoogleMapsScript();
  
    // Initialize Google Places Autocomplete
    const initAutocomplete = () => {
      const bransonLocation = new window.google.maps.LatLng(36.6436731, -93.2185144);
      const options = {
        bounds: new window.google.maps.Circle({ center: bransonLocation, radius: 67140 }).getBounds(),
        strictBounds: true,
      };
  
      // Initialize Google Places Autocomplete for the pickup input
      const autocompletePickup = new window.google.maps.places.Autocomplete(document.getElementById('pickup-input'), options);
      autocompletePickup.addListener('place_changed', () => {
        const place = autocompletePickup.getPlace();
        if (place.geometry) {
          setPickupAddress(place.formatted_address);
          setPickupCoordinates([place.geometry.location.lng(), place.geometry.location.lat()]);
          setIsDropoffEnabled(true);
        }
      });
  
      // Initialize Google Places Autocomplete for the dropoff input
      const autocompleteDropoff = new window.google.maps.places.Autocomplete(document.getElementById('dropoff-input'), options);
      autocompleteDropoff.addListener('place_changed', () => {
        const place = autocompleteDropoff.getPlace();
        if (place.geometry) {
          setDropoffAddress(place.formatted_address);
          setDropoffCoordinates([place.geometry.location.lng(), place.geometry.location.lat()]);
          setRoutePreviewed(true);
        }
      });
    };
  
    window.initAutocomplete = initAutocomplete;
    loadGoogleMapsScript();
  }, []);

  const handleConfirmAddress = (type) => {
    if (type === 'pickup' && pickupCoordinates) {
      setIsDropoffEnabled(true); // Enable the dropoff field if valid pickup coordinates are set
    } else if (type === 'dropoff' && pickupCoordinates && dropoffCoordinates) {
      setRoutePreviewed(true); // Set routePreviewed to true to change the button state
    } else {
      alert('Please select a valid address from the suggestions.');
    }
  };
  const handleConfirmLocations = () => {
    if (pickupCoordinates && dropoffCoordinates) {
      // Format the date for URL using dayjs to ensure it's in UTC ISO format
      const formattedDate = dayjs(datetime).utc().toISOString();
      const formattedPickupCoordinates = pickupCoordinates.join(',');
      const formattedDropoffCoordinates = dropoffCoordinates.join(',');
  
      // Navigate to the next page with all the necessary information
      navigate(`/setprice/${username}/${formattedDate}/${formattedPickupCoordinates}/${formattedDropoffCoordinates}/${pickupAddress}/${dropoffAddress}`);
    } else {
      alert('Please enter valid pickup and dropoff locations.');
    }
  };
  const handleNext = () => {
    // Logic to validate pickup address and fetch coordinates
    setStep(step + 1);
  };

  const handleBack = () => {
    // Logic to validate pickup address and fetch coordinates
    navigate(`/selectdate/${username}`);
  };




  return (
    <CustomThemeProvider>
    <AppBar position="fixed" style={{ backgroundColor: '#001623', zIndex: 1200 }}>
      <TopBar/>
    </AppBar>
    <Container maxWidth="sm" sx={{ py: 4, mt: 13 }}> {/* Adjust the top margin to account for the AppBar */}
    <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop:'30px' }}>
        <CardContent sx={{ px: 2, py: 3,backgroundColor: '#e2e3e0', }}>

          <Typography variant="subtitle4" component="subtitle4" sx={{ marginLeft:'-60px'}}>
          <IconButton
            size="small"
            onClick={handleBack}
     id="back-booking"
     position="end"
     sx={{ marginRight:'30px'}}
          >
            <ArrowBackIcon /></IconButton>
            <b sx={{}}>Pickup and dropoff locations</b>
          </Typography>   
          <Stack direction="column" spacing={-3}>
     
          
          <Slide direction="left" in={step === 1} mountOnEnter unmountOnExit style={{
            marginBottom:"-32px", 
          }}>
        <TextField
          id="pickup-input"
          label="Pickup Location"
        
          value={pickupAddress}
          onChange={(e) => setPickupAddress(e.target.value)}
         
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  color="info"
                  disabled={!pickupCoordinates}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </Slide>
      <Slide direction="down" in={step === 2}  >
      <TextField
    id="dropoff-input"
    label="Dropoff Location"
    value={dropoffAddress}
    onChange={(e) => setDropoffAddress(e.target.value)}
  
    InputProps={{
      startAdornment: (
        <InputAdornment position="end">
        
         
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <Button
            color={routePreviewed ? "success" : "primary"}
            disabled={!dropoffCoordinates}
            onClick={handleConfirmLocations}
          >
            {routePreviewed ? 'Confirm' : 'Preview'}
          </Button>
        </InputAdornment>
              
            ),
          }}
        />
      </Slide>
          </Stack>
          {!routePreviewed && (
            <div id="map" style={{ height: '260px', width:'100%'}} />
          )}
          {dropoffCoordinates && (
            <Slide style={{ height: '260px', width:'100%'}}
            >
            <MapPreview 
              pickupCoordinates={pickupCoordinates}
              dropoffCoordinates={dropoffCoordinates}
            /></Slide>
          )}
        </CardContent>
        {step === 3 && (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmLocations}
                >
                  Confirm
                </Button>
              </div>
            )}
      </Card>
      </Container>
      </CustomThemeProvider>

  );
};

export default SetLocations;





