import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CustomThemeProvider } from '../../theme';
import axios from 'axios'; // Now we're using axios
import TopBarBooking from '../../components/TopBarBooking';
import ForwardSharpIcon from '@mui/icons-material/ForwardSharp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Container, Card, CardContent, Typography, Button, TextField , ButtonBase, IconButton, Divider } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TopBar from '../TopBar';
import timezone from 'dayjs/plugin/timezone';
axios.defaults.withCredentials = true;
const SelectDate = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("America/Chicago");
  const navigate = useNavigate();
  const { username } = useParams();

  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());

  const handleDateTimeChange = (newValue) => {
    // Convert the selected date to a UTC date string
    const utcDateString = newValue.utc().toISOString();
    // Create a new Date object using the UTC date string
    const bookingDateUTC = new Date(utcDateString);
    // Convert the UTC Date object to a local time string
    const bookingDateLocal = bookingDateUTC.toLocaleString('en-US', { timeZone: 'America/Chicago' });
    // Update the state with the local time
    setSelectedDateTime(dayjs(bookingDateLocal));
  };

  const handleNextStep = () => {
    // Pass the UTC date string to the next step
    navigate(`/selectlocations/${username}/${selectedDateTime.utc().toISOString()}`);
  };

  return (
    <CustomThemeProvider>
      <TopBar />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container maxWidth="sm" sx={{ py: 4, mt: 10 }}>
          <Card sx={{boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#e2e3e0', marginTop:'30px'  }}>
            <CardContent sx={{ px: 6, py: 6, mb:2 }}>
            <Typography component="h4" variant="h5" align="center" sx={{fontSize:'17px'}}>
   
    <strong>When do you need to leave?</strong>
   
  </Typography>
  <Divider></Divider>
              <br></br>
              <MobileDateTimePicker sx={{ width: "90%",  '& .MuiInputBase-input': {
     
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // added this line
          padding: '12px 12px',
          border: '1px solid #ced4da',
          width:'100%',

        
        
        }, }}
                value={selectedDateTime}
                onChange={handleDateTimeChange}
                renderInput={(params) => <TextField {...params} />}
              /> <br></br>     <Button variant="contained" color="primary" onClick={handleNextStep} sx={{  fontSize: 17, px: 2, py: 1, width: '90%', marginTop: "9px", color:"#dee6e1" }}>
              <ArrowForwardIcon sx={{fontSize:'30px'}}/>
            </Button>
          
            </CardContent>
          </Card>
        </Container>
      </LocalizationProvider>
    </CustomThemeProvider>
  );
};

export default SelectDate;