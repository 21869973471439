import React, {useEffect, useState } from 'react';
import { useNavigate, userCredential } from 'react-router-dom';
import axios from 'axios';
import { Card, CardContent, TextField, Button, Typography, Container, IconButton, Divider} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TopBarBooking from '../components/TopBarBooking'; // Assuming you have a TopBarBooking component
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import '../App.css';



axios.defaults.withCredentials = true;
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const savedEmail = localStorage.getItem('emailForSignUp');
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      await setPersistence(auth, browserLocalPersistence);
      const result = await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });
      const user = result.user;
      console.log('User logged in:', user);

      const tokenResult = await FirebaseAuthentication.getIdToken(true);
      const token = tokenResult.token;
      console.log('ID Token:', token);

      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const { email_verified } = decodedToken;

      localStorage.setItem('userToken', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      if (token) {
        console.log('Sending POST request to /login');
        const response = await axios.post(`https://bransonrideshare.com/login`, {
          uid: user.uid,
          email: user.email,
          email_verified: email_verified
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        console.log('Server response:', response.data);
      }

      navigate('/'); // Navigate to the user dashboard
    } catch (error) {
      console.error('Login failed:', error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Login failed: ${error.response.data.error}`);
      } else {
        alert('Login failed. Please check your credentials and try again.');
      }
    }
  };

  const sendPasswordResetEmail = async () => {
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    try {
      await FirebaseAuthentication.sendPasswordResetEmail({ email });
      alert('You will receive an email if an account is found. (Please check spam folder)');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Failed to send password reset email: ' + error.message);
    }
  };

  const handleBack = () => {
    navigate(`/`);
  };

  return (
    <Container component="main" maxWidth="xs">
      <TopBarBooking />
      <Card sx={{ mt: 24, p: 1, backgroundColor: '#e2e3e0' }}>
        <CardContent sx={{ marginTop: '3px', backgroundColor: '#e2e3e0' }}>
          <Typography component="h6" variant="h6" sx={{ margin: '10px' }}>
            <Typography component="h1" variant="h5" align="left">
              <IconButton size="small" onClick={handleBack} sx={{ position: 'absolute', width: '14%' }}>
                <ArrowBackIcon />
              </IconButton>
            </Typography>
            <strong>Login</strong>
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              margin="dense"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
              sx={{
                '& .MuiInputBase-input': {
                  borderRadius: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  padding: '12px 12px',
                  border: '1px solid #ced4da',
                  fontSize: '16px',
                },
              }}
              placeholder="Email"
            />

    <Divider sx={{marginTop:'5px'}}></Divider>
    <TextField
      margin="dense" // changed from "normal" to "dense"
      required
      fullWidth
      name="password"
    
      type="password"
      id="password"
      autoComplete="current-password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      sx={{
        '& .MuiInputBase-input': {
          borderRadius: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // added this line
          padding: '12px 12px',
          border: '1px solid #ced4da',
          fontSize: '16px',
        },
      }}
       placeholder="Password"
    />
               <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{
        mt: 1,
        mb: 1,
        backgroundColor: "#717a68",
        borderRadius: 0,
        fontSize: '24px',
        padding: '15px 10px',
      }}
    >
     Login
    </Button>
          </form>
          <Button
            fullWidth
            variant="text"
            onClick={sendPasswordResetEmail}
            sx={{
            
           
              fontSize: '12px',
              textTransform: 'none',
              color:'#555956'
            }}
          >
            Forgot Password?
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;