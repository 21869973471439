import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import { CustomThemeProvider } from '../theme';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import ButtonBase from '@mui/material/ButtonBase';
import '../App.css';

function TopBarBooking({ handleLogout }) {
  const navigate = useNavigate(); // Hook for navigation

  return (
    <AppBar position="absolute" style={{ backgroundColor: '#172229'}}> {/* WAS: backgroundColor: '#1C322B' */}
      <CustomThemeProvider>
      <Toolbar>
        <Box display="flex" justifyContent="flex-start" width="100%">
          {/* Invisible spacer to balance the space on the left side */}
          <Box flex={1} />
          <Box sx={{ cursor: 'pointer' }}>
  <ButtonBase className="button-base" onClick={() => navigate('/')} style={{ display: 'inline-block', lineHeight: 0 }}>
    <img src="/fullogo.png" alt="Branson Rideshare Logo" style={{ height: "150px", maxWidth: '380px', borderRadius: "0 0 25px 25px"

    }} />
  </ButtonBase>
</Box>

          <Box flex={1} display="flex" justifyContent="center" alignItems="center" >

          </Box>
          {/* Log out button */}
        </Box>

        </Toolbar>
      </CustomThemeProvider>
    </AppBar>
  );
}

export default TopBarBooking;